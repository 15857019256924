import styled, { css } from 'styled-components/macro';
import {
  CONTROLS_HEIGHT,
  SHOW_SCREEN_SHARE_PARTICIPANTS_MAX,
  SIDEBAR_WIDTH,
} from './const';
import { pulse } from 'lib/style/animation';

type LayoutWrapperProps = {
  hasSidebar: boolean;
};

export const SessionWrapper = styled.div<LayoutWrapperProps>`
  width: 100vw;
  height: 100svh;
  max-height: 100%;
  overflow: hidden;
  position: relative;
  background: #141518;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ hasSidebar }) =>
    hasSidebar &&
    css`
      justify-content: flex-end;
    `}
`;

type ParticipantsContainerProps = {
  itemWidth: string;
  cols: string;
  isSidebar: boolean;
};

export const ParticipantsContainer = styled.div<ParticipantsContainerProps>`
  display: flex;
  ${({ isSidebar, itemWidth, cols }) =>
    !isSidebar &&
    css`
      padding-bottom: ${`${CONTROLS_HEIGHT}px`};
      max-width: calc(${itemWidth} * ${cols});
      justify-content: center;
      flex-wrap: wrap;
    `}
  ${({ isSidebar }) =>
    isSidebar &&
    css`
      padding: 0px 16px ${`${CONTROLS_HEIGHT}px`};
      width: ${SIDEBAR_WIDTH}px;
      height: 100svh;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      overflow-y: auto;
      overflow-x: hidden;
      ${({ theme }) =>
        theme.mediaQueryMaxWidth[SHOW_SCREEN_SHARE_PARTICIPANTS_MAX]} {
        display: none;
      }
    `}
`;

type ParticipantItemProps = {
  itemWidth: string;
  itemHeight: string;
  order: number;
};

export const ParticipantItem = styled.div<ParticipantItemProps>`
  position: relative;
  border: 4px solid #141518;
  border-radius: 8px;
  overflow: hidden;
  order: ${({ order }) => order};
  flex-shrink: 0;
  flex-grow: 0;
  > video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  ${({ itemWidth, itemHeight, order }) => css`
    order: ${order};
    width: ${itemWidth};
    height: ${itemHeight};
  `}
`;

type VideoWrapperProps = {
  isLoading?: boolean;
};

export const ParticipantBoxWrapper = styled.div<VideoWrapperProps>`
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  &:hover,
  &:focus,
  &:active {
    .participant-remove-button {
      display: block;
    }
  }
  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0.4;
    `}
`;

export const ScreenWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - ${SIDEBAR_WIDTH}px);
  height: calc(100svh - ${CONTROLS_HEIGHT}px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  border: 4px solid #141518;
  ${({ theme }) =>
    theme.mediaQueryMaxWidth[SHOW_SCREEN_SHARE_PARTICIPANTS_MAX]} {
    height: 100svh;
    width: 100%;
  }
`;

export const ScreenShareLoading = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 6;
`;

type VideoProps = { isScreen?: boolean; isSpeaking: boolean };

export const Video = styled.video<VideoProps>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: ${({ theme }) => theme.colors.neutral[100]};
  border: 1px solid
    ${({ isSpeaking, theme }) =>
      isSpeaking ? theme.colors.primary[80] : 'transparent'};
  border-radius: 8px;
  ${({ isScreen }) =>
    isScreen &&
    css`
      object-fit: contain;
    `}
`;

export const CameraOffWrapper = styled.div`
  position: absolute;
  z-index: 0;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: 1px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.neutral[100]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PeerInfoWrapper = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 4px;
  background: rgba(39, 42, 50, 0.6);
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 18px;
  > div {
    max-width: 90px;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ${({ theme }) => theme.mediaQueryMaxWidth.xs} {
    padding: 4px 8px;
    gap: 4px;
    > div {
      max-width: 60px;
      font-size: 10px;
    }
  }
`;

export const PeerName = styled.div<{ hasException?: boolean }>`
  color: ${({ theme }) => theme.colors.white[100]};
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  ${({ hasException, theme }) =>
    hasException &&
    css`
      color: ${theme.colors.danger[100]};
    `}
  ${({ theme }) => theme.mediaQueryMaxWidth.sm} {
    font-size: 12px;
  }
`;

export const ScreenShareInfoWrapper = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  background: rgba(39, 42, 50, 0.6);
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 18px;
`;

export const ScreenShareInfo = styled.div`
  color: ${({ theme }) => theme.colors.white[100]};
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  ${({ theme }) => theme.mediaQueryMaxWidth.sm} {
    font-size: 12px;
  }
`;

export const MicStatusWrapper = styled.div<{ status: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background: none;
  ${({ status }) =>
    status &&
    css`
      background: rgba(0, 0, 0, 0.2);
    `}
`;

export const MicStatus = styled.div<{ isSpeaking: boolean }>`
  position: absolute;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 255, 0, 1);
  margin: 10px;
  height: 20px;
  width: 20px;
  scale: 1;
  backface-visibility: hidden;
  ${({ isSpeaking }) =>
    isSpeaking &&
    css`
      animation: ${pulse} 1.5s infinite;
    `}
`;
