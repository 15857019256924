import React from 'react';
import Select, { DropdownIndicatorProps, components } from 'react-select';
import styled, { css } from 'styled-components/macro';
import { MdArrowDropDown } from 'react-icons/md';
import { CustomSelecProps } from './types';
import { useCovideoTheme } from 'react-covideo-common';

const DropdownWrapper = styled.div<{ width?: number }>`
  display: inline-block;
  max-width: ${({ width }) => (width ? `${width}px` : '100%')};
  min-width: ${({ width }) => (width ? `${width}px` : '100%')};
`;

const DropdownTypography = css`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  text-align: left;
`;

const SDropdown = styled(Select)`
  .dropdown__control {
    padding: 0 12px;
    border-radius: 6px;
    border-color: ${({ theme }) => theme.colors.neutral[20]};
    &:hover {
      border-color: ${({ theme }) => theme.colors.primary[20]};
    }
    .dropdown__placeholder {
      color: ${({ theme }) => theme.colors.neutral[60]};
      ${DropdownTypography};
    }
  }
  .dropdown__value-container {
    padding: 0;
  }

  .dropdown__control.dropdown__control--is-focused,
  .dropdown__control.dropdown__control--menu-is-open {
    border-color: ${({ theme }) => theme.colors.primary[100]};
    box-shadow: none;
  }
  .dropdown__single-value {
    margin: 0;
    ${DropdownTypography};
    color: ${({ theme }) => theme.colors.primary[100]};
  }
` as Select;

const DropdownIndicator = (props: DropdownIndicatorProps<any, false>) => {
  const {
    selectProps: { menuIsOpen },
  } = props;
  return (
    <components.DropdownIndicator {...props}>
      <MdArrowDropDown
        transform={menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
      />
    </components.DropdownIndicator>
  );
};

const IndicatorSeparator = () => {
  return null;
};

export const CustomSelect = (props: CustomSelecProps) => {
  const { width } = props;
  const theme = useCovideoTheme();
  return (
    <DropdownWrapper width={width}>
      <SDropdown
        {...props}
        isClearable={false}
        classNamePrefix='dropdown'
        components={{ DropdownIndicator, IndicatorSeparator }}
        menuPortalTarget={document.body}
        menuPosition='fixed'
        menuPlacement='bottom'
        styles={{
          menuPortal: base => ({
            ...base,
            zIndex: 9999,
          }),
          option: (base, { isFocused, isSelected }) => ({
            ...base,
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
            padding: '8px 16px',
            cursor: 'pointer',
            fontFamily: 'Work Sans',
            fontStyle: 'normal',
            fontWeight: isSelected ? 600 : 400,
            fontSize: '15px',
            lineHeight: '24px',
            textAlign: 'left',
            color:
              isFocused || isSelected
                ? theme.colors.primary[100]
                : theme.colors.neutral[100],
            background:
              isFocused || isSelected
                ? theme.colors.primary[10]
                : 'transparent',
          }),
          singleValue: base => ({
            ...base,
            color: `${theme.colors.black[100]} !important`, // Ensures text in the container appears red
          }),
        }}
      />
    </DropdownWrapper>
  );
};
