import { useAuth } from 'lib/context/auth/AuthProvider';
import { useOpenViduProvider } from 'lib/context/openvidu/OpenViduProvider';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ParticipantVideo } from '../participant/ParticipantVideo';
import { MainNavigation } from 'lib/components/navigation/MainNavigation';
import { Heading, ParagraphSmall } from 'lib/style/typography';
import { useMeeting } from 'lib/context/meeting/MeetingProvider';
import styled from 'styled-components/macro';
import { NoPermissions } from 'lib/components/info/NoPermissions';
import { Controls } from '../controls/Controls';
import { PERMISSION_STATUS } from 'lib/context/openvidu/const';
import { MainContainer } from 'lib/style/layout';
import { MobileControls } from '../controls/MobileControls';
import { Form, Formik } from 'formik';
import { TextField } from 'lib/components/formik/input';
import { useMeetingRequestContext } from 'lib/context/meetingRequest/MeetingRequestProvider';
import { APPROVAL_STATUS } from 'lib/api/meeting/types';
import { WaitingApprovalInfo } from 'lib/components/info/WaitingApprovalInfo';
import { getRandomNickname } from 'lib/utils/utils';
import { MeetingFullInfo } from 'lib/components/info/MeetingFullInfo';
import { PARTICIPANT_VIDEO_ASPECT_RATIO } from '../../const';
import { Button, useCovideoTheme } from 'react-covideo-common';

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: 32px;
`;

const Wrapper = styled.div`
  background: #4e5461;
  width: 700px;
  max-width: 100%;
  aspect-ratio: ${PARTICIPANT_VIDEO_ASPECT_RATIO};
  border-radius: 8px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
`;

const FormStyled = styled(Form)`
  display: flex;
  flex-direction: row;
  gap: 12px;
  max-width: 100%;
  margin: 0 auto;
`;

export const Lobby = () => {
  const { userData } = useAuth();
  const {
    meeting,
    isMeetingLoading,
    isMeetingHost,
    notifyHostParticipantArrived,
    updateMeetingToStarted,
  } = useMeeting();
  const theme = useCovideoTheme();
  const { id } = useParams<{ id: string }>();
  const {
    initCameraPreview,
    joinSession,
    localParticipant,
    permissionStatus,
    leaveSession,
    session,
    isSessionLoading,
  } = useOpenViduProvider();
  const {
    createRequest,
    activeRequest: currentRequest,
    isApprovalFailed,
  } = useMeetingRequestContext();
  const [isJoining, setIsJoining] = useState(false);
  useEffect(() => {
    if (session) {
      leaveSession();
    }
    initCameraPreview();
  }, []);

  useEffect(() => {
    if (isMeetingLoading || !meeting || isMeetingHost) {
      return;
    }
    notifyHostParticipantArrived();
  }, [meeting, isMeetingHost, isMeetingLoading]);

  const handleJoinMeeting = async (formikValues: { nickname: string }) => {
    setIsJoining(true);
    if (isMeetingHost) {
      await updateMeetingToStarted();
    }
    // approval needed if not host and hostApproval flag set or limited to 1on1
    const needApproval =
      !isMeetingHost &&
      (Number(meeting?.maxParticipantCapacity) <= 2 || !!meeting?.hostApproval);
    const nickname =
      formikValues?.nickname || userData?.firstName || getRandomNickname();

    // only create request if approval needed
    if (needApproval) {
      createRequest(nickname);
      setIsJoining(false);
      return;
    }

    // try join meeting immediately if host or approval not needed
    try {
      await joinSession(id, nickname);
      setIsJoining(false);
      return;
    } catch (error) {
      // if join fail and not host create request
      // TODO: check fail reason
      if (!isMeetingHost) {
        createRequest(nickname || localParticipant.nickname);
        setIsJoining(false);
      }
    }
  };

  const isPublisherReady = !!localParticipant?.streamManager;
  const isWaitingApproval =
    currentRequest?.approvalStatus === APPROVAL_STATUS.requested;
  const isLoading = isWaitingApproval || isSessionLoading || isJoining;
  const showMeetingFullInfo = isWaitingApproval && isApprovalFailed;

  return (
    <>
      <MainContainer>
        <MainNavigation
          extendStyle={{ container: { marginBottom: 20 } }}
          imageUrl={meeting?.landingImageUrl}
        />
        <Heading
          color={theme.colors.black[100]}
          m='0 0 8px 0'
          textAlign='center'
        >
          Join Meeting?
        </Heading>
        <ParagraphSmall
          color={theme.colors.black[100]}
          m='0 0 24px 0'
          textAlign='center'
        >
          Take a look at the preview before joining
        </ParagraphSmall>
        <MainWrapper>
          <Wrapper>
            {permissionStatus === PERMISSION_STATUS.DENIED && <NoPermissions />}
            {isWaitingApproval && <WaitingApprovalInfo />}
            {showMeetingFullInfo && <MeetingFullInfo />}
            {isPublisherReady && (
              <ParticipantVideo participant={localParticipant} />
            )}
            {!isLoading && (
              <>
                <Controls />
                <MobileControls />
              </>
            )}
          </Wrapper>
          <Formik
            initialValues={{ nickname: userData?.firstName || '' }}
            onSubmit={handleJoinMeeting}
          >
            {({ isSubmitting }) => (
              <FormStyled>
                {!isSubmitting && !isLoading && (
                  <TextField
                    name='nickname'
                    placeholder='Enter your name'
                    type='text'
                  />
                )}
                <Button
                  type='submit'
                  text={isSubmitting || isLoading ? 'Joining...' : 'Join Now'}
                  disabled={!isPublisherReady || isSubmitting || isLoading}
                />
              </FormStyled>
            )}
          </Formik>
        </MainWrapper>
      </MainContainer>
    </>
  );
};
