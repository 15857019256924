export enum ISOCKET_ACTIONS {
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  JOIN_MEETING = 'join-meeting',
  LEAVE_MEETING = 'leave-meeting',
  HOST_STARTED_MEETING = 'HOST_STARTED_MEETING',
  HOST_ENDED_MEETING = 'HOST_ENDED_MEETING',
  HOST_UPDATED_MEETING_SETTINGS = 'HOST_UPDATED_MEETING_SETTINGS',
  NEW_MEETING_REQUEST = 'NEW_MEETING_REQUEST',
  APPROVED_MEETING_REQUEST = 'APPROVED_MEETING_REQUEST',
  FAILED_MEETING_REQUEST = 'FAILED_MEETING_REQUEST',
}

type ISOCKET_ON_TYPES = {
  CONNECT: ISOCKET_ACTIONS.CONNECT;
  DISCONNECT: ISOCKET_ACTIONS.DISCONNECT;
  HOST_STARTED_MEETING: ISOCKET_ACTIONS.HOST_STARTED_MEETING;
  HOST_ENDED_MEETING: ISOCKET_ACTIONS.HOST_ENDED_MEETING;
  HOST_UPDATED_MEETING_SETTINGS: ISOCKET_ACTIONS.HOST_UPDATED_MEETING_SETTINGS;
  NEW_MEETING_REQUEST: ISOCKET_ACTIONS.NEW_MEETING_REQUEST;
  APPROVED_MEETING_REQUEST: ISOCKET_ACTIONS.APPROVED_MEETING_REQUEST;
  FAILED_MEETING_REQUEST: ISOCKET_ACTIONS.FAILED_MEETING_REQUEST;
};

type ISOCKET_EMIT_TYPES = {
  LEAVE_MEETING: ISOCKET_ACTIONS.LEAVE_MEETING;
  JOIN_MEETING: ISOCKET_ACTIONS.JOIN_MEETING;
  HOST_STARTED_MEETING: ISOCKET_ACTIONS.HOST_STARTED_MEETING;
  HOST_ENDED_MEETING: ISOCKET_ACTIONS.HOST_ENDED_MEETING;
  HOST_UPDATED_MEETING_SETTINGS: ISOCKET_ACTIONS.HOST_UPDATED_MEETING_SETTINGS;
  NEW_MEETING_REQUEST: ISOCKET_ACTIONS.NEW_MEETING_REQUEST;
  APPROVED_MEETING_REQUEST: ISOCKET_ACTIONS.APPROVED_MEETING_REQUEST;
  FAILED_MEETING_REQUEST: ISOCKET_ACTIONS.FAILED_MEETING_REQUEST;
};

type IValueOF<T> = T[keyof T];

export interface ISocket {
  id: string;
  on: <T>(name: IValueOF<ISOCKET_ON_TYPES>, cb: (id: T) => void) => void;
  off: <T>(name: IValueOF<ISOCKET_ON_TYPES>, cb: (id: T) => void) => void;
  emit: <T>(name: IValueOF<ISOCKET_EMIT_TYPES>, id: T) => void;
  disconnect: () => void;
  join: (roomId: string) => void;
  removeAllListeners: (name: IValueOF<ISOCKET_ON_TYPES>) => void;
}
