import { useCallback } from 'react';
import userLeaveSound from '../constants/sounds/user-leave.wav';
import userJoinSound from '../constants/sounds/user-join.wav';
import recordingStartSound from '../constants/sounds/recording-start.wav';
import recordingStopSound from '../constants/sounds/recording-stop.wav';
import errorSound from '../constants/sounds/error.wav';
import newRequestSound from '../constants/sounds/new-request.wav';
import chatSound from '../constants/sounds/chat.mp3';

enum SoundType {
  UserLeaveSound = 'userLeaveSound',
  UserJoinSound = 'userJoinSound',
  RecordingStartSound = 'recordingStartSound',
  RecordingStopSound = 'recordingStopSound',
  NewRequestSound = 'newRequestSound',
  ErrorSound = 'errorSound',
  ChatSound = 'chatSound',
}

const soundTypes: Record<SoundType, string> = {
  [SoundType.UserLeaveSound]: userLeaveSound,
  [SoundType.UserJoinSound]: userJoinSound,
  [SoundType.RecordingStartSound]: recordingStartSound,
  [SoundType.RecordingStopSound]: recordingStopSound,
  [SoundType.ErrorSound]: errorSound,
  [SoundType.NewRequestSound]: newRequestSound,
  [SoundType.ChatSound]: chatSound,
};

export const useSoundPlayer = () => {
  const playSound = async (soundKey: SoundType) => {
    const soundSrc = soundTypes[soundKey];
    if (!soundSrc) {
      return;
    }

    const audio = new Audio(soundSrc);
    audio.volume = 0.1;
    try {
      await audio.play();
    } catch (error) {
      console.log('Something went wrong when playing sound.', error);
    }
  };

  const playParticipantJoinedSound = useCallback(() => {
    playSound(SoundType.UserJoinSound);
  }, [playSound]);

  const playParticipantLeaveSound = useCallback(() => {
    playSound(SoundType.UserLeaveSound);
  }, [playSound]);

  const playChatUnreadSound = useCallback(() => {
    playSound(SoundType.ChatSound);
  }, [playSound]);

  const playRecordingStartSound = useCallback(() => {
    playSound(SoundType.RecordingStartSound);
  }, [playSound]);

  const playRecordingStopSound = useCallback(() => {
    playSound(SoundType.RecordingStopSound);
  }, [playSound]);

  const playNewRequestSound = useCallback(() => {
    playSound(SoundType.NewRequestSound);
  }, [playSound]);

  const playApprovedRequestSound = useCallback(() => {
    playSound(SoundType.NewRequestSound);
  }, [playSound]);

  return {
    playParticipantJoinedSound,
    playParticipantLeaveSound,
    playChatUnreadSound,
    playRecordingStartSound,
    playRecordingStopSound,
    playNewRequestSound,
    playApprovedRequestSound,
  };
};
