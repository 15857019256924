import { useQuery } from 'react-query';
import { meetingKeys } from './meetingKeys';
import { IMeeting } from './types';
import api from '..';

const getMeeting = async (meetingId: string): Promise<IMeeting> => {
  const response = await api.get(`/live/${meetingId}`);
  const { data: meeting } = response;
  return meeting as IMeeting;
};

export const useGetSingleMeeting = (meetingId?: string) => {
  return useQuery(
    meetingKeys.detail(meetingId),
    () => getMeeting(meetingId || ''),
    {
      enabled: !!meetingId,
    }
  );
};
