import React, { createContext, useContext, useEffect, useState } from 'react';
import { MeetingMessage } from 'lib/api/meeting/types';
import { useMeeting } from '../meeting/MeetingProvider';
import { useOpenViduProvider } from '../openvidu/OpenViduProvider';
import { SIGNAL } from '../openvidu/type';
import { useGetMeetingMessages } from 'lib/api/meeting/getMeetingMessages';
import { useSendMeetingMessageMutation } from 'lib/api/meeting/sendMeetingMessage';
import { useSoundPlayer } from 'lib/hooks';

interface IChatContext {
  messages: MeetingMessage[];
  showChat: boolean;
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  sendMeetingMessage: (val: string) => void;
  updateShowChat: (val: boolean) => void;
}

const ChatContext = createContext({} as IChatContext);

export const ChatProvider = ({ children }: { children: React.ReactNode }) => {
  const { sendSignal, localParticipant, isChatUnread, updateIsChatUnread } =
    useOpenViduProvider();
  const { playChatUnreadSound } = useSoundPlayer();
  const { meeting } = useMeeting();
  const {
    isLoading,
    isFetching,
    isError,
    data: messages = [],
  } = useGetMeetingMessages(meeting?.meetingId);
  const sendMessage = useSendMeetingMessageMutation();
  const [showChat, setShowChat] = useState(false);

  useEffect(() => {
    if (showChat && isChatUnread) {
      updateIsChatUnread(false);
    }
    if (!showChat && isChatUnread) {
      playChatUnreadSound();
    }
  }, [showChat, isChatUnread]);

  const sendMeetingMessage = async (text: string) => {
    await sendMessage.mutateAsync({
      meetingId: meeting?.meetingId,
      data: {
        text,
        connectionId: localParticipant.connectionId,
        nickname: localParticipant.nickname,
      },
    });
    sendSignal(SIGNAL.newMessage, {});
  };
  const updateShowChat = (val: boolean) => setShowChat(val);

  const values: IChatContext = {
    messages,
    showChat,
    sendMeetingMessage,
    updateShowChat,
    isLoading,
    isFetching,
    isError,
  };

  return <ChatContext.Provider value={values}>{children}</ChatContext.Provider>;
};

export const useChat = () => {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context;
};
