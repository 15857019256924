const screenSizes = {
  mb: 420,
  xs: 640,
  sm: 768,
  md: 1024,
  lg: 1200,
  xlg: 1368,
  xxlg: 1750,
  cmd: 920,
};

const mediaQueryMaxWidth = {
  mb: `@media (max-width: ${screenSizes.mb}px)`,
  xs: `@media (max-width: ${screenSizes.xs}px)`,
  sm: `@media (max-width: ${screenSizes.sm}px)`,
  md: `@media (max-width: ${screenSizes.md}px)`,
  lg: `@media (max-width: ${screenSizes.lg}px)`,
  xlg: `@media (max-width: ${screenSizes.xlg}px)`,
  xxlg: `@media (max-width: ${screenSizes.xxlg}px)`,
  cmd: `@media (max-width: ${screenSizes.cmd}px)`,
};

export const designSystem = {
  mediaQueryMaxWidth,
};
