import React from 'react';
import { ParagraphSmall } from 'lib/style/typography';
import styled from 'styled-components/macro';
import { ReactComponent as AppStore } from 'app/assets/images/appStore.svg';
import { ReactComponent as GoogleStore } from 'app/assets/images/googleStore.svg';
import { isAndroid, isIOS } from 'lib/utils/utils';
import { useCovideoTheme } from 'react-covideo-common';

const Wrapper = styled.div`
  display: none;
  ${({ theme }) => theme.mediaQueryMaxWidth.sm} {
    display: block;
    width: 300px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
`;

export const MeetingInfo = () => {
  const theme = useCovideoTheme();

  const renderNull = true;

  if (renderNull) {
    return null;
  }
  return (
    <>
      {(isIOS || isAndroid) && (
        <Wrapper>
          <ParagraphSmall color={theme.colors.neutral[60]} textAlign='center'>
            Hosting a meeting is possible only via Covideo Mobile App or desktop
          </ParagraphSmall>
          {isIOS && <AppStore />}
          {isAndroid && <GoogleStore />}
        </Wrapper>
      )}
    </>
  );
};
