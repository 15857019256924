import * as React from 'react';
import styled from 'styled-components/macro';
import { Spinner } from './Spinner';
import { ParagraphBold } from 'lib/style/typography';
import { useCovideoTheme } from 'react-covideo-common';

type Props = {
  text?: string;
  color?: string;
  height?: number | string;
};

type ContainerProps = {
  height?: number | string;
  zIndex?: number;
};

const LoadingOverlay = styled.div<ContainerProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  line-height: 50px;
  margin: 0;
`;

const LoadingSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
`;

const LoadingContainer = styled.div<ContainerProps>`
  position: inherit;
`;

export const LoadingIndicator = ({ text = 'Loading...' }: Props) => {
  const theme = useCovideoTheme();
  return (
    <LoadingContainer>
      <LoadingOverlay>
        <LoadingSection>
          <div>
            <Spinner color={theme.colors.black[100]} />
          </div>
          {!!text && (
            <ParagraphBold color={theme.colors.black[100]}>
              {text}
            </ParagraphBold>
          )}
        </LoadingSection>
      </LoadingOverlay>
    </LoadingContainer>
  );
};
