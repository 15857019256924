import { IoMdClose } from 'react-icons/io';
import { useChat } from 'lib/context/chat/ChatProvider';
import { useOpenViduProvider } from 'lib/context/openvidu/OpenViduProvider';
import { SendMeetingMessageForm } from 'lib/forms/SendMeetingMessageForm';
import { Flex } from 'lib/style/layout';
import { Heading2, ParagraphExtraSmall } from 'lib/style/typography';
import { addLinksToText, calculateDiff } from 'lib/utils/utils';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { useCovideoTheme } from 'react-covideo-common';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 360px;
  max-width: 100%;
  height: 100svh;
  background: ${({ theme }) => theme.colors.neutral[5]};
  z-index: 2;
  ${({ theme }) => theme.mediaQueryMaxWidth.sm} {
    z-index: 99;
  }
`;

const Header = styled.div`
  background: ${({ theme }) => theme.colors.white[100]};
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 24px rgba(0, 27, 83, 0.06);
  padding: 0 16px;
`;

const Body = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 56px;
  overflow-y: auto;
  bottom: 160px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  ${({ theme }) => theme.mediaQueryMaxWidth.sm} {
    bottom: 72px;
  }
`;

const MessageWrapper = styled.div<{ remote?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: ${({ remote }) => (remote ? 'flex-start' : 'flex-end')};
`;

const Message = styled.div<{ remote?: boolean }>`
  word-break: break-word;
  width: 100%;
  background: ${({ theme, remote }) =>
    remote ? theme.colors.white[100] : theme.colors.primary[100]};
  color: ${({ theme, remote }) =>
    remote ? theme.colors.primary[100] : theme.colors.white[100]};
  padding: 12px 16px;
  border-radius: ${({ remote }) =>
    remote ? '8px 8px 8px 0px' : '8px 8px 0px 8px'};
  * {
    color: ${({ theme, remote }) =>
      remote ? theme.colors.primary[100] : theme.colors.white[100]};
  }
`;

const Footer = styled.div`
  background: ${({ theme }) => theme.colors.white[100]};
  box-shadow: 0px 0px 24px rgba(0, 27, 83, 0.06);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

const WriteMessage = styled.div`
  height: 72px;
  padding: 16px;
  margin-bottom: 88px;
  ${({ theme }) => theme.mediaQueryMaxWidth.sm} {
    margin-bottom: 0;
  }
`;

const CloseModal = styled.div`
  cursor: pointer;
`;

interface Props {
  handleClose: () => void;
}

export const Chat = ({ handleClose }: Props) => {
  const { messages } = useChat();
  const { localParticipant } = useOpenViduProvider();
  const theme = useCovideoTheme();
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = chatContainerRef.current;
    if (!container) {
      return;
    }
    // scroll to bottom initially
    container.scrollTop = container.scrollHeight;

    const observer = new MutationObserver(() => {
      // scroll to bottom when a change in number of messages is observed
      container.scrollTop = container.scrollHeight;
    });

    observer.observe(container, { childList: true });
    return () => observer.disconnect();
  }, []);
  return (
    <Wrapper>
      <Header>
        <Heading2 color={theme.colors.black[100]}>Live Chat</Heading2>
        <CloseModal onClick={handleClose}>
          <IoMdClose color={theme.colors.neutral[60]} />
        </CloseModal>
      </Header>
      <Body ref={chatContainerRef}>
        {messages.map((message, i) => {
          const isRemote =
            message.connectionId !== localParticipant.connectionId;
          const showSender =
            !messages[i + 1] ||
            message.connectionId !== messages[i + 1].connectionId;
          return (
            <MessageWrapper key={i} remote={isRemote}>
              <Message
                dangerouslySetInnerHTML={{
                  __html: addLinksToText(message.text),
                }}
                remote={isRemote}
              />
              {showSender && (
                <Flex
                  alignItems='center'
                  gap='4px'
                  justifyContent={isRemote ? 'flex-start' : 'flex-end'}
                >
                  <ParagraphExtraSmall>
                    {!isRemote ? 'You' : message.nickname}
                  </ParagraphExtraSmall>
                  <ParagraphExtraSmall>·</ParagraphExtraSmall>
                  <ParagraphExtraSmall>
                    {calculateDiff(message.createdAt)}
                  </ParagraphExtraSmall>
                </Flex>
              )}
            </MessageWrapper>
          );
        })}
      </Body>
      <Footer>
        <WriteMessage>
          <SendMeetingMessageForm />
        </WriteMessage>
      </Footer>
    </Wrapper>
  );
};
