export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export enum THEME_NAME {
  AUTOMOTIVE = 'automotive',
  COVIDEO = 'covideo',
  KENNECTED = 'kennected',
  PROVIDEO = 'provideo',
  APP_2_VID = 'app2vid',
  BRANDING = 'branding',
  CDK = 'cdk',
}
