import React from 'react';
import { useField, useFormikContext } from 'formik';
import { CustomSelect } from '../form-parts/Dropdown';
import { FieldSelectProps } from '../form-parts/types';
import styled from 'styled-components/macro';
import { Label } from '../form-parts/Label';
import { ParagraphSmall } from 'lib/style/typography';
import { useCovideoTheme } from 'react-covideo-common';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.white[100]};
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const FieldSelect = (props: FieldSelectProps) => {
  const { name, onChange, label, error, ...rest } = props;
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();
  const theme = useCovideoTheme();

  return (
    <Wrapper>
      {label && <Label htmlFor={props.name} label={label} />}
      <CustomSelect
        {...field}
        onChange={e => {
          setFieldValue(name, e);
          //e.g. we use on change if we need to update some external store
          onChange?.(e);
        }}
        {...rest}
      />
      {!!error && (
        <ParagraphSmall color={theme.colors.danger[100]}>
          {error}
        </ParagraphSmall>
      )}
    </Wrapper>
  );
};
