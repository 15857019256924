import React from 'react';
import styled from 'styled-components/macro';

const Indicator = styled.div`
  top: 6px;
  width: 8px;
  height: 8px;
  background: ${({ theme }) => theme.colors.primary[100]};
  border-radius: 16px;
  z-index: 2;
  right: 56px;
  position: absolute;
  ${({ theme }) => theme.mediaQueryMaxWidth.sm} {
    right: -3px;
    top: -6px;
  }
`;

export const NofificationIndicator = () => {
  return <Indicator />;
};
