import React, { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  MeetingProvider,
  useMeeting,
} from 'lib/context/meeting/MeetingProvider';
import { Session } from './components/rooms/Session';
import {
  OpenViduProvider,
  useOpenViduProvider,
} from 'lib/context/openvidu/OpenViduProvider';
import { Lobby } from './components/rooms/Lobby';
import { LoadingIndicator } from 'lib/components/loader/Loader';
import { NotFound } from '../not-found/NotFound';
import { MEETING_STATUS } from 'lib/api/meeting/types';
import { meetingShouldStartSoon } from 'lib/utils/utils';
import { Scheduled } from './components/rooms/Scheduled';
import { Finished } from './components/rooms/Finished';
import { ChatProvider } from 'lib/context/chat/ChatProvider';
import { ROOM, RoomProvider, useRoom } from 'lib/context/room/RoomProvider';
import { MeetingRequestProvider } from 'lib/context/meetingRequest/MeetingRequestProvider';
import { useSockets } from 'lib/context/socket/SocketProvider';
import { ISOCKET_ACTIONS } from 'lib/context/socket/types';

export const MeetingRoomsWrapper = memo(() => {
  const { id: meetingId } = useParams<{ id: string }>();
  return (
    <RoomProvider>
      <MeetingProvider meetingId={meetingId}>
        <OpenViduProvider>
          <MeetingRequestProvider>
            <ChooseRoom />
          </MeetingRequestProvider>
        </OpenViduProvider>
      </MeetingProvider>
    </RoomProvider>
  );
});

const ChooseRoom = memo(() => {
  const { meeting, isMeetingLoading, isMeetingFetching, isError } =
    useMeeting();
  const { isSessionLoading } = useOpenViduProvider();
  const { room, changeRoom } = useRoom();
  const { socket } = useSockets();

  useEffect(() => {
    if (!meeting || !socket) {
      return;
    }
    socket.emit(ISOCKET_ACTIONS.JOIN_MEETING, { meetingId: meeting.meetingId });
    const cleanup = () => {
      socket.emit(ISOCKET_ACTIONS.LEAVE_MEETING, {
        meetingId: meeting.meetingId,
      });
    };
    return cleanup;
  }, [socket, meeting]);

  useEffect(() => {
    if (isMeetingLoading || isMeetingFetching || isSessionLoading) {
      changeRoom(ROOM.LOADING);
      return;
    }
    if (!meeting || isError) {
      changeRoom(ROOM.ERROR);
      return;
    }
    if (meeting.meetingStatus === MEETING_STATUS.FINISHED) {
      changeRoom(ROOM.FINISHED);
      return;
    }
    if (!meetingShouldStartSoon(meeting)) {
      changeRoom(ROOM.SCHEDULED);
      return;
    }

    changeRoom(ROOM.LOBBY);
    return;
  }, [isMeetingLoading, isMeetingFetching, meeting, isError]);

  switch (room) {
    case ROOM.LOADING:
      return <LoadingIndicator />;
    case ROOM.ERROR:
      return <NotFound />;
    case ROOM.SCHEDULED:
      return <Scheduled />;
    case ROOM.FINISHED:
      return <Finished />;
    case ROOM.LOBBY:
      return <Lobby />;
    case ROOM.SESSION:
      return (
        <ChatProvider>
          <Session />
        </ChatProvider>
      );
    default:
      return <LoadingIndicator />;
  }
});
