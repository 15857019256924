import { useCovideoTheme } from 'react-covideo-common';
import CovideoLogo from 'app/assets/images/logo.svg';
import CDKLogo from 'app/assets/images/cdkLogo.svg';
import { THEME_NAME } from 'types/utils';

export const useLogoUrl = (): {
  logoUrl: string;
} => {
  const { themeName } = useCovideoTheme();
  if (themeName === THEME_NAME.CDK) {
    return { logoUrl: CDKLogo };
  }
  return { logoUrl: CovideoLogo };
};
