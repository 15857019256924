import { AxiosError } from 'axios';
import { useToastError } from 'lib/hooks';
import { MutationFunction, useMutation } from 'react-query';
import api from '..';
import { IStopRecordingSessionRequest, IRecording } from './types';

const stopRecordingSession: MutationFunction<
  IRecording,
  IStopRecordingSessionRequest
> = async ({ recordingId, sessionId }) => {
  const response = await api.post(`/openvidu/recording/${sessionId}/stop`, {
    recordingId,
  });
  if (!response.data) {
    throw new Error('No data found');
  }
  return response.data;
};

export const useStopRecordingSessionMutation = () => {
  const { showError } = useToastError();

  return useMutation<IRecording, AxiosError, IStopRecordingSessionRequest>(
    stopRecordingSession,
    {
      onError: (err: AxiosError) => {
        showError(err, 'Something went wrong when stopping recording.');
      },
    }
  );
};
