import React, { useEffect } from 'react';
import { useMeeting } from 'lib/context/meeting/MeetingProvider';
import { Flex, MainContainer } from 'lib/style/layout';
import { MainNavigation } from 'lib/components/navigation/MainNavigation';
import { ReactComponent as LiveScheduled } from 'app/assets/images/liveScheduled.svg';
import { Heading, Heading2, ParagraphSmall } from 'lib/style/typography';
import styled from 'styled-components/macro';
import { generateScheduledTime } from 'lib/utils/utils';
import { useSockets } from 'lib/context/socket/SocketProvider';
import { ISOCKET_ACTIONS } from 'lib/context/socket/types';
import { infoSuccessToast } from 'lib/components/toasts/infoSuccess';
import { MEETING_STATUS } from 'lib/api/meeting/types';
import { Button, useCovideoTheme } from 'react-covideo-common';

const Image = styled(LiveScheduled)`
  max-width: 100%;
  display: block;
  margin: 0 auto;
`;

export const Scheduled = () => {
  const { meeting, updateMeetingToStarted, isMeetingHost, invalidateMeeting } =
    useMeeting();
  const theme = useCovideoTheme();
  const { socket } = useSockets();

  useEffect(() => {
    if (!meeting || !socket || isMeetingHost) {
      return;
    }
    const handleMeetingStarted = () => {
      infoSuccessToast({ title: `Host started the meeting. ` });
      invalidateMeeting();
    };
    socket.on(ISOCKET_ACTIONS.HOST_STARTED_MEETING, handleMeetingStarted);
    const cleanup = () => {
      socket.removeAllListeners(ISOCKET_ACTIONS.HOST_STARTED_MEETING);
    };
    return cleanup;
  }, [meeting, socket]);

  const handleStartScheduledMeeting = async () => {
    if (!meeting) {
      return;
    }
    await updateMeetingToStarted();
  };

  const { scheduledDateTime, scheduledTimezone } =
    generateScheduledTime(meeting);
  return (
    <MainContainer>
      <MainNavigation
        extendStyle={{ container: { marginBottom: 64 } }}
        imageUrl={meeting?.landingImageUrl}
      />
      <Flex
        alignItems='center'
        flexDirection='column'
        gap='16px'
        m='0 0 48px 0'
      >
        <Heading color={theme.colors.black[100]} textAlign='center'>
          {!scheduledDateTime && 'Meeting is scheduled. '}Come back later.
        </Heading>
        {scheduledDateTime && (
          <>
            <Heading2
              color={theme.colors.black[100]}
              textAlign='center'
              style={{ lineBreak: 'auto' }}
            >
              Meeting is scheduled for {scheduledDateTime}
            </Heading2>
            <ParagraphSmall
              color={theme.colors.black[100]}
              textAlign='center'
              style={{ lineBreak: 'auto' }}
            >
              {scheduledTimezone}
            </ParagraphSmall>
          </>
        )}
      </Flex>
      {isMeetingHost && meeting?.meetingStatus !== MEETING_STATUS.RECURRING && (
        <Flex justifyContent='center' m='0 0 24px 0'>
          <Button
            text='Start Meeting Now'
            onClick={handleStartScheduledMeeting}
          />
        </Flex>
      )}
      <Image />
    </MainContainer>
  );
};
