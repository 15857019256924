import React from 'react';
import { IoMdInformationCircle } from 'react-icons/io';
import { toast } from 'react-toastify';

import { BaseNotificationBody } from './base';
import { CloseToastIcon } from './close-icon';
import { ToastProps } from './types';
import { useCovideoTheme } from 'react-covideo-common';

const InfoSuccessNotification = (props: ToastProps) => {
  const theme = useCovideoTheme();
  return (
    <BaseNotificationBody {...props}>
      <IoMdInformationCircle
        color={theme.colors.primary[100]}
        size={26}
        style={{ minWidth: 26, maxWidth: 26 }}
      />
    </BaseNotificationBody>
  );
};

export const infoSuccessToast = (props: ToastProps) =>
  toast(<InfoSuccessNotification {...props} type='infoSuccess' />, {
    progressStyle: { background: 'transparent' },
    position: props.position || 'top-right',
    closeButton: <CloseToastIcon {...props} />,
  });
