import copy from 'copy-to-clipboard';
import { IMeeting } from 'lib/api/meeting/types';
import { infoSuccessToast } from 'lib/components/toasts/infoSuccess';
import { ParagraphSmall, ParagraphSmallBold } from 'lib/style/typography';
import { formatMeetingTime } from 'lib/utils/utils';
import * as React from 'react';
import { useCovideoTheme } from 'react-covideo-common';
import { MdOutlineContentCopy } from 'react-icons/md';
import styled from 'styled-components/macro';

interface MeetingData {
  title: string;
  value: string;
}
type Props = {
  isMeetingInProgress?: boolean;
  meeting: IMeeting;
};
type InfoWrapperProps = {
  clickable?: boolean;
};

const MainWrapper = styled.div<{ isMeetingInProgress: boolean }>`
  display: block;
  position: absolute;
  padding: 0px 0px 8px 0px;
  width: 400px;
  max-height: 266px;
  height: auto;
  background-color: white;
  z-index: 99;
  border-radius: 8px;
  left: 16px;
  bottom: ${({ isMeetingInProgress }) =>
    isMeetingInProgress ? '76px' : '50px'};
`;
const InfoWrapper = styled.div<InfoWrapperProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 12px;
  gap: 8px;
  height: 36px;
  align-self: stretch;
  font-family: 'Work Sans';
  cursor: ${props => (props.clickable ? 'pointer' : '')};
  svg {
    position: relative;
    margin-right: 1px;
    top: 13px;
  }
`;

export const MeetingInfo = ({ isMeetingInProgress, meeting }: Props) => {
  const theme = useCovideoTheme();
  const formattedMeetingTime = !!meeting.deliveryTime
    ? formatMeetingTime(meeting.deliveryTime, meeting.duration)
    : { day: '', meetingDuration: '' };
  const meetingData: MeetingData[] = [
    {
      title: 'Title:',
      value: meeting.title || '',
    },
    {
      title: 'Date:',
      value: formattedMeetingTime.day || '',
    },
    {
      title: 'Time:',
      value: formattedMeetingTime.meetingDuration || '',
    },
    {
      title: 'Participant:',
      value: `${meeting.firstName || ''} ${meeting.lastName || ''}`,
    },
    {
      title: 'Organizer:',
      value: `${meeting.host.firstName || ''} ${meeting.host.lastName || ''}`,
    },
    {
      title: 'Meeting ID:',
      value: meeting.meetingId || '',
    },
  ];

  const onCopyClick = () => {
    copy(window.location.href);
    infoSuccessToast({ title: 'Meeting Link copied successfully.' });
  };

  return (
    <MainWrapper isMeetingInProgress={!!isMeetingInProgress}>
      {meetingData.map(
        (element, i) =>
          !!element.value.trim() && (
            <InfoWrapper key={i}>
              <ParagraphSmallBold
                color={theme.colors.primary[100]}
                style={{
                  flex: 'none',
                  order: 0,
                  flexGrow: 1,
                  marginTop: 'auto',
                }}
              >
                {element.title}
              </ParagraphSmallBold>
              <ParagraphSmall
                color={theme.colors.black[100]}
                style={{
                  flex: 'none',
                  order: 1,
                  flexGrow: 0,
                  marginTop: 'auto',
                }}
              >
                {element.value}
              </ParagraphSmall>
            </InfoWrapper>
          )
      )}
      <InfoWrapper
        clickable={true}
        onClick={() => onCopyClick()}
        style={{
          padding: '2px 12px',
        }}
      >
        <MdOutlineContentCopy color={theme.colors.primary[40]} />
        <ParagraphSmallBold
          color={theme.colors.primary[100]}
          style={{ flex: 'none', order: 0, flexGrow: 1, marginTop: 'auto' }}
        >
          Copy Meeting Link
        </ParagraphSmallBold>
      </InfoWrapper>
    </MainWrapper>
  );
};
