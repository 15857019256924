import { Formik, Form } from 'formik';
import { TextField } from 'lib/components/formik/input';
import React from 'react';
import { Button } from 'react-covideo-common';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

interface IEnterMeetingValues {
  meetingId: string;
}

const validationSchema = Yup.object().shape({
  meetingId: Yup.string().required('Required'),
});

export const JoinMeetingForm = () => {
  const history = useHistory();

  const initialValues: IEnterMeetingValues = {
    meetingId: '',
  };

  const onSubmitHandler = (formikValues: IEnterMeetingValues) => {
    const { meetingId } = formikValues;
    if (!meetingId) {
      return;
    }
    history.push(meetingId);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandler}
    >
      {({ isSubmitting }) => (
        <Form style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
          <TextField
            name='meetingId'
            label='Meeting ID:'
            placeholder='Enter meeting ID'
            type='text'
          />
          <Button
            style={{ margin: '0 auto' }}
            type='submit'
            disabled={isSubmitting}
            text={isSubmitting ? 'Joining...' : 'Join Now'}
          />
        </Form>
      )}
    </Formik>
  );
};
