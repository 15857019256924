import { AxiosError } from 'axios';
import { useToastError } from 'lib/hooks';
import { MutationFunction, useMutation } from 'react-query';
import api from '..';
import { IStartRecordingSessionRequest, IRecording } from './types';

const startRecordingSession: MutationFunction<
  IRecording,
  IStartRecordingSessionRequest
> = async ({ sessionId }) => {
  const response = await api.post(`/openvidu/recording/${sessionId}/start`);
  if (!response.data) {
    throw new Error('No data found');
  }
  return response.data;
};

export const useStartRecordingSessionMutation = () => {
  const { showError } = useToastError();

  return useMutation<IRecording, AxiosError, IStartRecordingSessionRequest>(
    startRecordingSession,
    {
      onError: (err: AxiosError) => {
        showError(err, 'Something went wrong when starting recording.');
      },
    }
  );
};
