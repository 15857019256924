import { AxiosError } from 'axios';
import { useToastError } from 'lib/hooks';
import { MutationFunction, useMutation } from 'react-query';
import api from '..';
import { ICreateSessionRequest, ICreateSessionResponse } from './types';

const createSession: MutationFunction<
  ICreateSessionResponse,
  ICreateSessionRequest
> = async ({ customSessionId }) => {
  const response = await api.post(`/openvidu/session`, { customSessionId });
  if (!response.data) {
    throw new Error('No data found');
  }
  return response.data;
};

export const useCreateSessionMutation = () => {
  const { showError } = useToastError();

  return useMutation<ICreateSessionResponse, AxiosError, ICreateSessionRequest>(
    createSession,
    {
      onError: (err: AxiosError) => {
        showError(err, 'Something went wrong when creating session.');
      },
    }
  );
};
