import { AxiosError } from 'axios';
import { useToastError } from 'lib/hooks';
import { MutationFunction, useMutation, useQueryClient } from 'react-query';
import api, { token } from '..';
import { meetingKeys } from './meetingKeys';
import { MeetingMessage, SendMeetingMessageParams } from './types';

const sendConversatioMessage: MutationFunction<
  MeetingMessage[],
  SendMeetingMessageParams
> = async ({ meetingId, data }: SendMeetingMessageParams) => {
  const isAuthorized = !!token;
  const response = await api.post(
    `/live/${meetingId}/${isAuthorized ? 'authorized' : 'guest'}-message`,
    data
  );
  if (!response.data) {
    throw new Error('No data found');
  }
  return response.data;
};

export const useSendMeetingMessageMutation = () => {
  const { showError } = useToastError();
  const queryClient = useQueryClient();

  return useMutation<MeetingMessage[], AxiosError, SendMeetingMessageParams>(
    sendConversatioMessage,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(meetingKeys.meetingMessages());
      },
      onError: (err: AxiosError) => {
        showError(err, 'Something went wrong when sending chat message.');
      },
    }
  );
};
