import React from 'react';
import { CustomModal } from 'lib/components/modal/main';
import { Flex } from 'lib/style/layout';
import { useOpenViduProvider } from 'lib/context/openvidu/OpenViduProvider';
import { useMeeting } from 'lib/context/meeting/MeetingProvider';
import { SIGNAL } from 'lib/context/openvidu/type';
import { ROOM, useRoom } from 'lib/context/room/RoomProvider';
import { MEETING_STATUS } from 'lib/api/meeting/types';
import { Button } from 'react-covideo-common';

type ModalProps = {
  handleClose: () => void;
};

const LeaveMeetingModal = ({ handleClose }: ModalProps) => {
  const { sendSignal, leaveSession } = useOpenViduProvider();
  const { updateMeetingToFinished, isMeetingHost, meeting } = useMeeting();
  const { changeRoom } = useRoom();

  const handleLeave = async () => {
    leaveSession();
    changeRoom(ROOM.LOBBY);
  };

  const handleEndForAll = async () => {
    if (!isMeetingHost) {
      return;
    }
    sendSignal(SIGNAL.endMeetingForAll, {});
    await updateMeetingToFinished();
  };

  const isRecurringMeeting =
    meeting?.meetingStatus === MEETING_STATUS.RECURRING;

  return (
    <CustomModal
      closeModal={handleClose}
      headerText='Do you want to leave the meeting?'
      style={{
        content: {
          width: '592px',
        },
      }}
    >
      <Flex gap='12px' alignItems='center' justifyContent='flex-end'>
        <Button variant='secondary' onClick={handleClose} text='No, Stay' />
        <Button variant='destructive' onClick={handleLeave} text='Yes, Leave' />
        {isMeetingHost && !isRecurringMeeting && (
          <Button
            variant='destructive'
            onClick={handleEndForAll}
            text='End Call for All'
          />
        )}
      </Flex>
    </CustomModal>
  );
};

export default LeaveMeetingModal;
