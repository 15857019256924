import { Formik, Form, FormikHelpers } from 'formik';
import { TextField } from 'lib/components/formik/input';
import { useAuth } from 'lib/context/auth/AuthProvider';
import React from 'react';
import { Button } from 'react-covideo-common';
import * as Yup from 'yup';

interface ILoginFormValues {
  username: string;
  password: string;
}
const validationSchema = Yup.object().shape({
  username: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
});

export const LoginForm = () => {
  const { login } = useAuth();

  const initialValues: ILoginFormValues = {
    username: '',
    password: '',
  };

  const onSubmitHandler = async (
    values: ILoginFormValues,
    formikContext: FormikHelpers<ILoginFormValues>
  ) => {
    const { setErrors, setSubmitting } = formikContext;
    try {
      await login(values);
    } catch (error) {
      setErrors({
        username: 'Invalid username or password',
        password: 'Invalid username or password',
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandler}
    >
      {({ isSubmitting }) => (
        <Form style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
          <TextField
            name='username'
            label='Username:'
            placeholder='Enter username'
            type='text'
          />

          <TextField
            name='password'
            label='Password:'
            placeholder='Enter password'
            type='password'
          />

          <Button
            style={{ margin: '0 auto' }}
            disabled={isSubmitting}
            type='submit'
            text={isSubmitting ? 'Logging in...' : 'Login'}
          />
        </Form>
      )}
    </Formik>
  );
};
