import { MeetingRequest } from 'lib/api/meeting/types';
import { Spinner } from 'lib/components/loader/Spinner';
import { useMeetingRequestContext } from 'lib/context/meetingRequest/MeetingRequestProvider';
import React, { useState } from 'react';
import { Button } from 'react-covideo-common';

interface Props {
  request: MeetingRequest;
}

export const ApproveRequestButton = ({ request }: Props) => {
  const { approveRequest } = useMeetingRequestContext();
  const [isLoading, setIsLoading] = useState(false);
  const onClick = async () => {
    try {
      setIsLoading(true);
      await approveRequest(request.meetingRequestId, request.browserHash);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  return (
    <Button
      text='Approve'
      onClick={onClick}
      disabled={isLoading}
      icon={isLoading ? <Spinner size={12} color='white' /> : undefined}
    />
  );
};
