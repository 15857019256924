import { StreamSettings } from 'app/pages/meeting/components/settings/StreamSettings';
import React from 'react';
import { CustomModal } from '../../../../../lib/components/modal/main';

export const ModalSettings = ({
  onModalClose,
}: {
  onModalClose: () => void;
}) => {
  return (
    <CustomModal
      style={{
        content: {
          width: '592px',
        },
      }}
      headerText='Settings'
      closeModal={onModalClose}
    >
      <StreamSettings onModalClose={onModalClose} />
    </CustomModal>
  );
};
