import { AxiosError } from 'axios';
import { MutationFunction, useMutation, useQueryClient } from 'react-query';
import api from '..';
import { meetingKeys } from './meetingKeys';
import { CreateMeetingRequestParams, MeetingRequest } from './types';

const createMeetingRequest: MutationFunction<
  MeetingRequest,
  CreateMeetingRequestParams
> = async ({ meetingId, data }: CreateMeetingRequestParams) => {
  const response = await api.post(`/live/${meetingId}/request`, data);
  if (!response.data) {
    throw new Error('No data found');
  }
  return response.data;
};

export const useCreateMeetingRequestMutation = () => {
  const queryClient = useQueryClient();
  return useMutation<MeetingRequest, AxiosError, CreateMeetingRequestParams>(
    createMeetingRequest,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(meetingKeys.meetingActiveRequest());
      },
    }
  );
};
