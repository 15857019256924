import React from 'react';
import { CustomModal } from 'lib/components/modal/main';
import { useMeetingRequestContext } from 'lib/context/meetingRequest/MeetingRequestProvider';
import { ParagraphBold } from 'lib/style/typography';
import styled from 'styled-components/macro';
import { ApproveRequestButton } from '../controls/ApproveRequestButton';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.neutral[10]};
`;

const Row = styled.div`
  padding: 6px;
  background: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ModalMeetingRequests = ({
  onModalClose,
}: {
  onModalClose: () => void;
}) => {
  const { requests } = useMeetingRequestContext();
  return (
    <CustomModal
      style={{
        content: {
          width: '592px',
        },
      }}
      headerText='Admission Requests'
      closeModal={onModalClose}
    >
      <Content>
        {requests.map(request => (
          <Row key={request.meetingRequestId}>
            <ParagraphBold>{request.nickname}</ParagraphBold>
            <ApproveRequestButton request={request} />
          </Row>
        ))}
      </Content>
    </CustomModal>
  );
};
