import styled, { css } from 'styled-components/macro';
interface ICommonTypography {
  m?: string;
  textAlign?: string;
  elispis?: boolean;
  width?: string;
  color?: string;
}

const CommonTypography = styled.h1<ICommonTypography>`
  font-family: 'Work Sans';

  margin: ${({ m }) => m || 0};

  color: ${({ color, theme }) => color || theme.colors.neutral[100]};

  text-align: ${({ textAlign }) => textAlign || 'left'};

  width: ${({ width }) => width || 'auto'};

  ${({ elispis }) =>
    elispis &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

export const Heading = styled(CommonTypography).attrs({ as: 'h1' })`
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
`;

export const Heading2 = styled(CommonTypography).attrs({ as: 'h2' })`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`;

export const ParagraphExtraSmall = styled(CommonTypography).attrs({ as: 'p' })`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
`;

export const ParagraphSmall = styled(CommonTypography).attrs({ as: 'p' })`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;

export const ParagraphSmallBold = styled(CommonTypography).attrs({ as: 'p' })`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
`;

export const ParagraphBold = styled(CommonTypography).attrs({ as: 'p' })`
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
`;
