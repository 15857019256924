import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { meetingKeys } from './meetingKeys';

export const useInvalidateMeetingQuery = () => {
  const queryClient = useQueryClient();

  const invalidateMeetingQuery = useCallback(
    (meetingId: string) => {
      queryClient.invalidateQueries(meetingKeys.detail(meetingId));
    },
    [queryClient]
  );

  return invalidateMeetingQuery;
};
