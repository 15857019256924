import { AxiosError } from 'axios';
import { MutationFunction, useMutation, useQueryClient } from 'react-query';
import api from '..';
import { meetingKeys } from './meetingKeys';
import { ApproveMeetingRequestParams, MeetingRequest } from './types';

const approveMeetingRequest: MutationFunction<
  MeetingRequest[],
  ApproveMeetingRequestParams
> = async ({ meetingId, meetingRequestId }: ApproveMeetingRequestParams) => {
  const response = await api.patch(
    `/live/${meetingId}/request/${meetingRequestId}/approve`
  );
  if (!response.data) {
    throw new Error('No data found');
  }
  return response.data;
};

export const useApproveMeetingRequestMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<MeetingRequest[], AxiosError, ApproveMeetingRequestParams>(
    approveMeetingRequest,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(meetingKeys.meetingRequests());
      },
      onError: () => {
        queryClient.invalidateQueries(meetingKeys.meetingRequests());
      },
    }
  );
};
