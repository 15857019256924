import { Formik, Form } from 'formik';
import { TextField } from 'lib/components/formik/input';
import { useChat } from 'lib/context/chat/ChatProvider';
import React, { useRef } from 'react';
import { Button } from 'react-covideo-common';
import { IoMdSend } from 'react-icons/io';

interface ISendMessageValues {
  text: string;
}

export const SendMeetingMessageForm = () => {
  const { sendMeetingMessage } = useChat();
  const inputRef = useRef<HTMLInputElement>(null);
  const initialValues: ISendMessageValues = {
    text: '',
  };

  const onSubmitHandler = (formikValues: ISendMessageValues) => {
    const { text } = formikValues;
    if (!text) {
      return;
    }
    sendMeetingMessage(text);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => {
        onSubmitHandler(values);
        resetForm();
      }}
    >
      {({ isSubmitting, values }) => (
        <Form style={{ display: 'flex', gap: 8 }}>
          <div style={{ width: '100%' }}>
            <TextField
              name='text'
              placeholder='Write a message...'
              type='text'
              autoComplete='off'
              autoFocus
              innerRef={inputRef}
              maxLength={500}
            />
          </div>
          <Button
            type='submit'
            disabled={!values?.text?.length || isSubmitting}
            icon={<IoMdSend size={19} />}
          />
        </Form>
      )}
    </Formik>
  );
};
