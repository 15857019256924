import styled from 'styled-components/macro';

export const LogoWrapper = styled.div`
  position: relative;
  width: 200px;
  margin: 0 auto;
  ${({ theme }) => theme.mediaQueryMaxWidth.sm} {
    margin-top: 32px;
    > svg {
      width: 100%;
    }
  }
`;
