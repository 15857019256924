import { AxiosError } from 'axios';
import { MutationFunction, useMutation } from 'react-query';
import api from '..';
import { RemoveParticipantParams } from '../meeting/types';

const removeParticipant: MutationFunction<
  void,
  RemoveParticipantParams
> = async ({ sessionId, connectionId }: RemoveParticipantParams) =>
  api.delete(`/openvidu/session/${sessionId}/connection/${connectionId}`);

export const useRemoveParticipantMutation = () => {
  return useMutation<void, AxiosError, RemoveParticipantParams>(
    removeParticipant
  );
};
