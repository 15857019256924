import React, { useState } from 'react';
import { MdOutlineRemoveCircleOutline } from 'react-icons/md';
import styled from 'styled-components/macro';
import { useOpenViduProvider } from 'lib/context/openvidu/OpenViduProvider';
import { Participant } from 'lib/context/openvidu/type';
import { useMeeting } from 'lib/context/meeting/MeetingProvider';
import { Spinner } from 'lib/components/loader/Spinner';
import { Button } from 'react-covideo-common';

const RemoveButtonWrapper = styled.div`
  display: none;
  position: absolute;
  right: 8px;
  top: 8px;
`;

interface Props {
  participant: Participant;
}

export const RemoveParticipantButton = ({ participant }: Props) => {
  const { removeParticipant } = useOpenViduProvider();
  const { isMeetingHost } = useMeeting();
  const [isLoading, setIsLoading] = useState(false);
  const shouldRenderRemoveButton =
    isMeetingHost && participant.type === 'remote' && !participant?.isScreen;
  if (!shouldRenderRemoveButton) {
    return null;
  }
  const onClick = async () => {
    try {
      setIsLoading(true);
      await removeParticipant(participant);
    } catch (error) {
      setIsLoading(false);
    }
  };
  return (
    <RemoveButtonWrapper className='participant-remove-button'>
      <Button
        text='Remove'
        variant='secondary'
        icon={
          isLoading ? <Spinner size={8} /> : <MdOutlineRemoveCircleOutline />
        }
        onClick={onClick}
        disabled={isLoading}
      />
    </RemoveButtonWrapper>
  );
};
