import React from 'react';
import { Heading, ParagraphSmall } from 'lib/style/typography';
import styled from 'styled-components/macro';
import { ROUTES } from 'app/routes/Routes';
import { Link, useHistory, useParams } from 'react-router-dom';
import { MainContainer } from 'lib/style/layout';
import { Button, useCovideoTheme } from 'react-covideo-common';
import { useLogoUrl } from 'lib/hooks/useLogoUrl';
import { LogoWrapper } from 'lib/style/logo';

const SideWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 12px;
  ${({ theme }) => theme.mediaQueryMaxWidth.sm} {
    top: 15px;
  }
`;

const ExtendedLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary[100]};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const Container = styled.div`
  display: flex;
  margin-bottom: 64px;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  padding-top: 68px;
  ${({ theme }) => theme.mediaQueryMaxWidth.sm} {
    margin-bottom: 32px;
    padding-top: 38px;
  }
`;

export const ParticipantRemoved = () => {
  const theme = useCovideoTheme();
  const history = useHistory();
  const { id: meetingId } = useParams<{ id: string }>();
  const { logoUrl } = useLogoUrl();
  return (
    <MainContainer>
      <Container>
        <LogoWrapper>
          <img style={{ width: '100%' }} src={logoUrl} alt='logo' />
        </LogoWrapper>

        <SideWrapper>
          <ParagraphSmall color={theme.colors.neutral[100]}>
            <>
              <span>Are you the host? </span>
              <ExtendedLink to={ROUTES.login.path}>Login</ExtendedLink>
            </>
          </ParagraphSmall>
        </SideWrapper>
      </Container>
      <div style={{ margin: '0 auto', textAlign: 'center' }}>
        <Heading
          textAlign='center'
          m='0 0 16px 0'
          color={theme.colors.black[100]}
        >
          Kicked out by Meeting Host
        </Heading>
        <Button
          style={{ margin: '0 auto' }}
          onClick={() => history.push(`/${meetingId}`)}
          text='Try Again?'
        />
      </div>
    </MainContainer>
  );
};
