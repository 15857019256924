import React from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`
  height: 36px;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  padding: 4px 12px;
  border-radius: 44px;

  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  height: 16px;
  width: 16px;
  margin-right: 3px;
`;

const Text = styled.div`
  font-size: 12px;
  font-weight: 600;
`;

type Props = {
  text: string;
  icon?: JSX.Element;
};

const Pill = ({ text, icon }: Props) => {
  return (
    <Container>
      {icon && <Icon>{icon}</Icon>}
      <Text>{text}</Text>
    </Container>
  );
};

export default Pill;
