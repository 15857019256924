import React from 'react';
import { CustomModal } from '../../../../../lib/components/modal/main';
import { Flex } from 'lib/style/layout';
import styled from 'styled-components/macro';
import { useOpenViduProvider } from 'lib/context/openvidu/OpenViduProvider';
import { Button } from 'react-covideo-common';

type Props = {
  handleClose: () => void;
};

const Body = styled.div`
  margin-bottom: 35px;
`;

const ScreenSharingModal = ({ handleClose }: Props) => {
  const {
    isScreenSharingDisabled,
    stopScreenSharing,
    startScreenSharing,
    isLocalScreenSharing,
  } = useOpenViduProvider();

  const handleSubmit = () => {
    if (isScreenSharingDisabled) {
      return;
    }
    if (isLocalScreenSharing) {
      stopScreenSharing();
    }
    if (!isLocalScreenSharing) {
      startScreenSharing();
    }
    handleClose();
  };

  const headerText = `${
    isLocalScreenSharing ? `Stop` : `Start`
  } Screen Sharing`;

  return (
    <CustomModal
      closeModal={handleClose}
      headerText={headerText}
      style={{
        content: {
          width: '592px',
        },
      }}
    >
      <Body>
        {isLocalScreenSharing
          ? 'Do you want to stop screen sharing?'
          : `Do you want to start screen sharing?`}
      </Body>
      <Flex gap='12px' alignItems='center' justifyContent='flex-end'>
        <Button variant='secondary' onClick={handleClose} text='No, Go Back' />
        <Button
          variant='red'
          onClick={handleSubmit}
          text={`${isLocalScreenSharing ? `Stop` : `Start`} Screen Sharing`}
        />
      </Flex>
    </CustomModal>
  );
};

export default ScreenSharingModal;
