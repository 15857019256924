import { Publisher, Subscriber } from 'openvidu-browser';

export type DeviceSettings = {
  audioDeviceId: string;
  videoDeviceId: string;
  resolution: string;
};

export type Participant = {
  connectionId: string;
  audioActive: boolean;
  videoActive: boolean;
  nickname: string;
  type: 'remote' | 'local';
  isHost: boolean;
  isScreen: boolean;
  isRecording: boolean;
  hasException: boolean;
  streamManager: Publisher | Subscriber | undefined;
};

export enum SIGNAL {
  userChanged = 'userChanged',
  newMessage = 'newMessage',
  screenShareLoading = 'screenShareLoading',
  endMeetingForAll = 'endMeetingForAll',
  removeParticipant = 'removeParticipant',
}
