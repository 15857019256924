import React from 'react';
import { useMeeting } from 'lib/context/meeting/MeetingProvider';
import { HoverPopup } from 'lib/style/tooltop';
import { BsCameraVideoFill, BsCameraVideoOffFill } from 'react-icons/bs';
import { IoMdCall } from 'react-icons/io';
import { Flex } from 'lib/style/layout';
import { IoMdChatboxes } from 'react-icons/io';
import styled, { css } from 'styled-components/macro';
import { IoMdMic, IoMdMicOff, IoMdSettings } from 'react-icons/io';
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdOutlineFlipCameraAndroid,
  MdOutlineScreenShare,
  MdOutlineStopScreenShare,
} from 'react-icons/md';
import { MeetingInfo } from '../modals/meeting-info';
import { Chat } from '../chat/Chat';
import { NofificationIndicator } from '../chat/NotificationIndicator';
import { BsRecord2 } from 'react-icons/bs';
import { MdStop } from 'react-icons/md';
import { useOpenViduProvider } from 'lib/context/openvidu/OpenViduProvider';
import { useChat } from 'lib/context/chat/ChatProvider';
import { Spinner } from 'lib/components/loader/Spinner';
import { ModalSettings } from '../modals/settings';
import LeaveMeetingModal from '../modals/leave-meeting';
import RecordMeetingModal from '../modals/record-meeting';
import ScreenSharingModal from '../modals/screen-sharing';
import { MEETING_MODAL, useRoom } from 'lib/context/room/RoomProvider';
import { SHOW_MOBILE_CONTROLS_MAX } from '../../const';
import { Button } from 'react-covideo-common';

const WaitingRoomContainer = styled.div<{ isMeetingInProgress: boolean }>`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  position: absolute;
  z-index: 3;
  bottom: 0;
  width: 100%;
  padding: 0 16px;
  left: 0;

  ${({ isMeetingInProgress }) =>
    isMeetingInProgress &&
    css`
      background: rgba(16, 32, 36, 0.8);
      height: 88px;
      align-items: center;
      margin-bottom: 0px;
    `}
  ${({ theme }) => theme.mediaQueryMaxWidth[SHOW_MOBILE_CONTROLS_MAX]} {
    display: none;
  }
`;

export const Controls = () => {
  const { showChat, updateShowChat } = useChat();
  const {
    localParticipant,
    changeMicStatus,
    changeCameraStatus,
    session,
    isChatUnread,
    isLocalScreenSharing,
    isScreenSharingDisabled,
    isRecordingLoading,
    isRecording,
    availableDevices,
    switchCamera,
  } = useOpenViduProvider();
  const { meeting, isMeetingHost } = useMeeting();
  const { meetingModal, changeMeetingModal } = useRoom();

  const isPublisherReady = !!localParticipant?.streamManager;
  const enableCameraControls = isPublisherReady;
  const enableMicControls = isPublisherReady;
  const isMeetingInProgress = !!session;

  const showScreenShareButton =
    (isMeetingHost || !!meeting?.enableGuestScreenShare) && isMeetingInProgress;

  const publisherReady = !!localParticipant?.streamManager;
  const hasMultipleVideoDevices =
    (availableDevices || []).filter(device => device.kind === 'videoinput')
      .length > 1;

  return (
    <>
      <WaitingRoomContainer isMeetingInProgress={isMeetingInProgress}>
        <Flex flex='0.25' flexWrap='nowrap'>
          {/* SETTINGS */}
          <HoverPopup
            width={!publisherReady ? '245px' : '85px'}
            position='top-left'
            padding='8px 12px'
            showTriangle
            hoverable={
              <Button
                variant='secondary'
                onClick={() => changeMeetingModal(MEETING_MODAL.SETTINGS)}
                disabled={!publisherReady}
                icon={<IoMdSettings size={18} />}
              />
            }
            popup={
              !publisherReady ? 'Camera is not working properly.' : 'Settings'
            }
          />
          {/* SWITCH CAMERA */}
          {hasMultipleVideoDevices && localParticipant.videoActive && (
            <HoverPopup
              width={!publisherReady ? '245px' : '115px'}
              position='top-left'
              padding='8px 12px'
              showTriangle
              hoverable={
                <Button
                  icon={<MdOutlineFlipCameraAndroid />}
                  variant='secondary'
                  onClick={() => switchCamera()}
                />
              }
              popup={
                !publisherReady
                  ? 'Camera is not working properly.'
                  : 'Flip Camera'
              }
            />
          )}
          {/* INFO */}
          {isMeetingInProgress && isMeetingHost && (
            <Button
              variant='secondary'
              onClick={() => {
                if (meetingModal === null) {
                  return changeMeetingModal(MEETING_MODAL.INFO);
                }
                return changeMeetingModal(null);
              }}
              text='Info'
              disabled={!meeting}
              icon={
                meetingModal === MEETING_MODAL.INFO ? (
                  <MdKeyboardArrowDown size={18} />
                ) : (
                  <MdKeyboardArrowUp size={18} />
                )
              }
            />
          )}
        </Flex>
        <Flex flex='0.5' justifyContent='center'>
          {/* MICROPHONE */}
          <HoverPopup
            width={enableMicControls ? '170px' : '230px'}
            position='above'
            padding='8px 12px'
            showTriangle
            hoverable={
              <Button
                variant={
                  localParticipant.audioActive ? 'secondary' : 'destructive'
                }
                icon={
                  localParticipant.audioActive ? (
                    <IoMdMic size={16} />
                  ) : (
                    <IoMdMicOff size={16} />
                  )
                }
                disabled={!enableMicControls}
                onClick={() => changeMicStatus()}
              />
            }
            popup={
              !enableMicControls
                ? 'Not able to use microphone'
                : localParticipant.audioActive
                  ? 'Turn Off Microphone'
                  : 'Turn On Microphone'
            }
          />
          {/* CAMERA */}
          <HoverPopup
            width={enableCameraControls ? '140px' : '200px'}
            position='above'
            padding='8px 12px'
            showTriangle
            hoverable={
              <Button
                variant={
                  localParticipant.videoActive ? 'secondary' : 'destructive'
                }
                onClick={() => changeCameraStatus()}
                disabled={!enableCameraControls}
                icon={
                  localParticipant.videoActive ? (
                    <BsCameraVideoFill size={16} />
                  ) : (
                    <BsCameraVideoOffFill size={16} />
                  )
                }
              />
            }
            popup={
              !enableCameraControls
                ? 'Not able to use camera'
                : localParticipant.videoActive
                  ? 'Turn Off Camera'
                  : 'Turn On Camera'
            }
          />
          {/* SCREEN SHARE */}
          {showScreenShareButton && (
            <HoverPopup
              width={isScreenSharingDisabled ? '195px' : '170px'}
              position='above'
              padding='8px 12px'
              showTriangle
              hoverable={
                <Button
                  variant={isLocalScreenSharing ? 'destructive' : 'secondary'}
                  onClick={() =>
                    changeMeetingModal(MEETING_MODAL.SCREEN_SHARING)
                  }
                  icon={
                    isLocalScreenSharing ? (
                      <MdOutlineStopScreenShare />
                    ) : (
                      <MdOutlineScreenShare />
                    )
                  }
                  disabled={isScreenSharingDisabled}
                />
              }
              popup={`${
                isScreenSharingDisabled
                  ? 'Screen Sharing Disabled'
                  : isLocalScreenSharing
                    ? `Stop Screen Sharing`
                    : `Start Screen Sharing`
              }`}
            />
          )}
          {/* START RECORDING */}{' '}
          {isMeetingInProgress && isMeetingHost && (
            <HoverPopup
              width={'140px'}
              position='above'
              padding='8px 12px'
              showTriangle
              hoverable={
                <Button
                  variant={isRecording ? 'destructive' : 'secondary'}
                  onClick={() => changeMeetingModal(MEETING_MODAL.RECORDING)}
                  icon={
                    isRecordingLoading ? (
                      <Spinner size={12} />
                    ) : isRecording ? (
                      <MdStop size={22} />
                    ) : (
                      <BsRecord2 size={24} />
                    )
                  }
                  disabled={isRecordingLoading}
                />
              }
              popup={`${isRecording ? `Stop Recording` : `Start Recording`}`}
            />
          )}
          {/* LEAVE CALL */}
          {isMeetingInProgress && (
            <HoverPopup
              width='100px'
              position='above'
              padding='8px 12px'
              showTriangle
              hoverable={
                <Button
                  variant='red'
                  onClick={() => changeMeetingModal(MEETING_MODAL.LEAVE)}
                  text='Leave Call'
                  icon={<IoMdCall size={18} />}
                />
              }
              popup='Leave call'
            />
          )}
        </Flex>
        {isMeetingInProgress && (
          <Flex
            flex='0.25'
            justifyContent='flex-end'
            style={{ position: 'relative' }}
          >
            {/* CHAT */}
            {isChatUnread && <NofificationIndicator />}

            <HoverPopup
              width='100px'
              position='above'
              padding='8px 12px'
              showTriangle
              hoverable={
                <Button
                  variant='secondary'
                  onClick={() => {
                    updateShowChat(!showChat);
                  }}
                  text='Chat'
                  icon={<IoMdChatboxes size={20} />}
                />
              }
              popup={showChat ? 'Close chat' : 'Open chat'}
            />
          </Flex>
        )}
      </WaitingRoomContainer>

      {meetingModal === MEETING_MODAL.SETTINGS && (
        <ModalSettings onModalClose={() => changeMeetingModal(null)} />
      )}

      {meetingModal === MEETING_MODAL.LEAVE && (
        <LeaveMeetingModal handleClose={() => changeMeetingModal(null)} />
      )}

      {meetingModal === MEETING_MODAL.INFO && meeting && (
        <MeetingInfo
          meeting={meeting}
          isMeetingInProgress={isMeetingInProgress}
        />
      )}

      {meetingModal === MEETING_MODAL.RECORDING && (
        <RecordMeetingModal handleClose={() => changeMeetingModal(null)} />
      )}

      {meetingModal === MEETING_MODAL.SCREEN_SHARING && (
        <ScreenSharingModal handleClose={() => changeMeetingModal(null)} />
      )}

      {showChat && (
        <Chat
          handleClose={() => {
            updateShowChat(false);
          }}
        />
      )}
    </>
  );
};
