import { IoMdClose } from 'react-icons/io';
import React, { memo, useRef } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components/macro';

type ModalStyleObject = {
  overlay?: React.CSSProperties;
  content?: React.CSSProperties;
};

type Props = {
  children?: React.ReactNode;
  style?: ModalStyleObject;
  hideHeader?: boolean;
  closeModal: () => void;
  headerText?: string;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
};

const defaultModalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(52,58,64, .75)',
    zIndex: 998,
  } as React.CSSProperties,
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '4px',
    padding: '32px',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 999,
  } as React.CSSProperties,
};

const ModalHeader = styled.div`
  color: ${({ theme }) => theme.colors.neutral[100]};
  margin-bottom: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  > div > svg {
    cursor: pointer;
  }
`;

const HeaderText = styled.p`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
`;

const IconWrapper = styled.div`
  display: flex;
  height: 24px;
  align-items: center;
`;

export const CustomModal = memo(
  ({
    children,
    style,
    hideHeader = false,
    headerText,
    closeModal,
    shouldCloseOnOverlayClick = true,
    shouldCloseOnEsc = true,
  }: Props) => {
    const modalRef = useRef<any>(null);
    return (
      <Modal
        ref={modalRef}
        isOpen={true}
        onRequestClose={closeModal}
        style={{
          overlay: { ...defaultModalStyles.overlay, ...style?.overlay },
          content: { ...defaultModalStyles.content, ...style?.content },
        }}
        ariaHideApp={false}
        contentLabel='Example Modal'
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        shouldCloseOnEsc={shouldCloseOnEsc}
      >
        {!hideHeader && (
          <ModalHeader>
            <HeaderText>{headerText}</HeaderText>
            <IconWrapper onClick={closeModal}>
              <IoMdClose />
            </IconWrapper>
          </ModalHeader>
        )}
        {children}
      </Modal>
    );
  }
);
