import React from 'react';
import { Heading, Heading2, ParagraphSmall } from 'lib/style/typography';
import notFoundImage from '../../assets/images/notFound.png';
import { ROUTES } from 'app/routes/Routes';
import { Link } from 'react-router-dom';
import { MainContainer } from 'lib/style/layout';
import { useCovideoTheme } from 'react-covideo-common';
import styled from 'styled-components/macro';
import { useLogoUrl } from 'lib/hooks/useLogoUrl';
import { LogoWrapper } from 'lib/style/logo';

const SideWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 12px;
  ${({ theme }) => theme.mediaQueryMaxWidth.sm} {
    top: 15px;
  }
`;

const ExtendedLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary[100]};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

const Container = styled.div`
  display: flex;
  margin-bottom: 64px;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  padding-top: 68px;
  ${({ theme }) => theme.mediaQueryMaxWidth.sm} {
    margin-bottom: 32px;
    padding-top: 38px;
  }
`;

const Graphic = styled.img`
  ${({ theme }) => theme.mediaQueryMaxWidth.sm} {
    width: 60%;
  }
`;
export const NotFound = () => {
  const theme = useCovideoTheme();
  const { logoUrl } = useLogoUrl();
  return (
    <MainContainer>
      <Container>
        <LogoWrapper>
          <img style={{ width: '100%' }} src={logoUrl} alt='logo' />
        </LogoWrapper>

        <SideWrapper>
          <ParagraphSmall color={theme.colors.neutral[60]}>
            <>
              <span>Are you the host? </span>
              <ExtendedLink to={ROUTES.login.path}>Login</ExtendedLink>
            </>
          </ParagraphSmall>
        </SideWrapper>
      </Container>
      <div style={{ margin: '0 auto', textAlign: 'center' }}>
        <Heading
          textAlign='center'
          m='0 0 16px 0'
          color={theme.colors.black[100]}
        >
          Meeting Not Found
        </Heading>
        <Heading2
          m='0 0 24px 0'
          textAlign='center'
          color={theme.colors.black[100]}
        >
          Please re-check the meeting ID and try again.
        </Heading2>
        <Graphic alt='not-found' src={notFoundImage} />
      </div>
    </MainContainer>
  );
};
