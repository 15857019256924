import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Helmet from 'react-helmet';
import { ROUTES } from './routes/Routes';
import 'react-toastify/dist/ReactToastify.css';
import { isCdkEnvironment } from 'lib/config';

function App() {
  const title = isCdkEnvironment ? 'CDK CRM Video' : 'Covideo Meetings';
  const faviconURL = isCdkEnvironment
    ? '/favicons/cdk/favicon.svg'
    : '/favicons/covideo/favicon.ico';
  return (
    <BrowserRouter>
      <Helmet>
        <title>{title}</title>
        <link rel='icon' href={faviconURL} type='image/x-icon' />
      </Helmet>
      <Switch>
        {Object.values(ROUTES).map(({ path, component }) => (
          <Route key={path} exact path={path} component={component} />
        ))}
      </Switch>
    </BrowserRouter>
  );
}

export default App;
