import React, {
  createContext,
  memo,
  useContext,
  useEffect,
  useState,
} from 'react';
import { APPROVAL_STATUS, MeetingRequest } from 'lib/api/meeting/types';
import { useMeeting } from '../meeting/MeetingProvider';
import { useOpenViduProvider } from '../openvidu/OpenViduProvider';
import { useCreateMeetingRequestMutation } from 'lib/api/meeting/createMeetingRequest';
import { useSockets } from '../socket/SocketProvider';
import { useGetMeetingApprovalRequests } from 'lib/api/meeting/getMeetingApprovalRequests';
import { useApproveMeetingRequestMutation } from 'lib/api/meeting/approveMeetingRequest';
import { ISOCKET_ACTIONS } from '../socket/types';
import { infoToast } from 'lib/components/toasts/info';
import { successToast } from 'lib/components/toasts/success';
import { v4 as uuidv4 } from 'uuid';
import { useSoundPlayer, useToastError } from 'lib/hooks';
import { useGetActiveMeetingRequest } from 'lib/api/meeting/getActiveMeetingRequest';
import { useQueryClient } from 'react-query';
import { meetingKeys } from 'lib/api/meeting/meetingKeys';
import { errorToast } from 'lib/components/toasts/error';
import { ROOM, useRoom } from '../room/RoomProvider';

interface IMeetingRequestContext {
  activeRequest: MeetingRequest | undefined;
  requests: MeetingRequest[];
  showApproveRequestsModal: boolean;
  isApprovalFailed: boolean;
  createRequest: (nickname: string) => Promise<void>;
  approveRequest: (
    meetingRequestId: number,
    browserHash: string
  ) => Promise<void>;
  updateShowApproveRequestsModal: (val: boolean) => void;
}

const MeetingRequestContext = createContext({} as IMeetingRequestContext);

export const MeetingRequestProvider = memo(
  ({ children }: { children: React.ReactNode }) => {
    const { playNewRequestSound, playApprovedRequestSound } = useSoundPlayer();
    let browserHash = localStorage.getItem('browserHash') || '';
    if (!browserHash) {
      browserHash = uuidv4();
      localStorage.setItem('browserHash', browserHash);
    }
    const queryClient = useQueryClient();
    const { socket } = useSockets();
    const { updateLocalParticipant, joinSession, session } =
      useOpenViduProvider();
    const { room } = useRoom();
    const { meeting, isMeetingHost } = useMeeting();
    const { data: requests = [] } = useGetMeetingApprovalRequests(
      meeting?.meetingId || '',
      isMeetingHost
    );
    const { data: activeRequest = null } = useGetActiveMeetingRequest(
      meeting?.meetingId || '',
      browserHash,
      isMeetingHost
    );
    const { mutateAsync: createMeetingRequest } =
      useCreateMeetingRequestMutation();
    const approveMeetingRequest = useApproveMeetingRequestMutation();
    const { showError } = useToastError();
    const [isApproved, setIsApproved] = useState(false);
    const [isApprovalFailed, setIsApprovalFailed] = useState(false);
    const [showApproveRequestsModal, setShowApproveRequestsModal] =
      useState(false);

    useEffect(() => {
      if (!meeting || !socket || !isMeetingHost) {
        return;
      }
      const handleNewMeetingRequest = (
        meetingRequestId: number,
        nickname: string,
        browserHash: string
      ) => {
        playNewRequestSound();
        const inSession = room === ROOM.SESSION;
        infoToast({
          title: `${nickname} wants to join Meeting`,
          additionalInfo: inSession
            ? undefined
            : 'Join Meeting to Approve guest',
          button: !inSession
            ? undefined
            : {
                text: 'Approve',
                action: () => approveRequest(meetingRequestId, browserHash),
              },
        });
        queryClient.invalidateQueries(meetingKeys.meetingRequests());
      };
      socket.on(
        ISOCKET_ACTIONS.NEW_MEETING_REQUEST,
        (data: {
          meetingRequestId: number;
          nickname: string;
          browserHash: string;
        }) => {
          handleNewMeetingRequest(
            data.meetingRequestId,
            data.nickname,
            data.browserHash
          );
        }
      );
      const cleanup = () => {
        socket.removeAllListeners(ISOCKET_ACTIONS.NEW_MEETING_REQUEST);
      };
      return cleanup;
    }, [meeting, socket, isMeetingHost, room]);

    useEffect(() => {
      if (!meeting || !socket || isMeetingHost) {
        return;
      }
      const handleApprovedMeetingRequest = (hash: string) => {
        if (hash !== browserHash) {
          return;
        }
        successToast({
          title: `Meeting Request Approved`,
          additionalInfo: 'Joining...',
        });
        playApprovedRequestSound();
        setIsApproved(true);
        setIsApprovalFailed(false);
        queryClient.invalidateQueries(meetingKeys.meetingActiveRequest());
      };
      const handleFailedMeetingRequest = (hash: string) => {
        if (hash !== browserHash) {
          return;
        }
        errorToast({
          title: `Meeting is full`,
          additionalInfo: 'Try again later...',
        });
        setIsApprovalFailed(true);
        queryClient.invalidateQueries(meetingKeys.meetingActiveRequest());
      };
      socket.on(
        ISOCKET_ACTIONS.APPROVED_MEETING_REQUEST,
        (data: { browserHash: string }) => {
          if (!data?.browserHash) {
            return;
          }
          handleApprovedMeetingRequest(data.browserHash);
        }
      );
      socket.on(
        ISOCKET_ACTIONS.FAILED_MEETING_REQUEST,
        (data: { browserHash: string }) => {
          if (!data?.browserHash) {
            return;
          }
          handleFailedMeetingRequest(data.browserHash);
        }
      );
      const cleanup = () => {
        socket.removeAllListeners(ISOCKET_ACTIONS.APPROVED_MEETING_REQUEST);
        socket.removeAllListeners(ISOCKET_ACTIONS.FAILED_MEETING_REQUEST);
      };
      return cleanup;
    }, [meeting, socket, isMeetingHost]);

    useEffect(() => {
      if (!meeting || session || !isApproved) {
        return;
      }
      joinSession(meeting.meetingId);
      setIsApproved(false);
    }, [meeting, isApproved, session]);

    const createRequest = async (nickname: string) => {
      if (!meeting?.meetingId || !socket?.id) {
        return;
      }
      updateLocalParticipant({ nickname });
      try {
        const request = await createMeetingRequest({
          meetingId: meeting.meetingId,
          data: {
            browserHash: browserHash,
            nickname: nickname,
          },
        });
        socket.emit(ISOCKET_ACTIONS.NEW_MEETING_REQUEST, {
          meetingId: meeting.meetingId,
          meetingRequestId: request.meetingRequestId,
          nickname,
          browserHash,
        });
      } catch (error) {
        showError(error, 'Something went wrong when creating meeting request.');
      }
    };

    const approveRequest = async (
      meetingRequestId: number,
      browserHash: string
    ) => {
      if (!meeting?.meetingId || !socket) {
        return;
      }
      try {
        await approveMeetingRequest.mutateAsync({
          meetingId: meeting.meetingId,
          meetingRequestId: meetingRequestId,
        });
        socket.emit(ISOCKET_ACTIONS.APPROVED_MEETING_REQUEST, {
          meetingId: meeting.meetingId,
          browserHash: browserHash,
        });
      } catch (error) {
        showError(
          error,
          'Something went wrong when approving meeting request.'
        );
        socket.emit(ISOCKET_ACTIONS.FAILED_MEETING_REQUEST, {
          meetingId: meeting.meetingId,
          browserHash: browserHash,
        });
      }
    };

    const updateShowApproveRequestsModal = (val: boolean) =>
      setShowApproveRequestsModal(val);
    const waitingApprovalRequests = (requests || []).filter(
      request => request.approvalStatus === APPROVAL_STATUS.requested
    );

    const values: IMeetingRequestContext = {
      isApprovalFailed,
      showApproveRequestsModal,
      activeRequest: activeRequest?.meetingRequest,
      requests: waitingApprovalRequests,
      createRequest,
      approveRequest,
      updateShowApproveRequestsModal,
    };

    return (
      <MeetingRequestContext.Provider value={values}>
        {children}
      </MeetingRequestContext.Provider>
    );
  }
);

export const useMeetingRequestContext = () => {
  const context = useContext(MeetingRequestContext);
  if (context === undefined) {
    throw new Error(
      'useApprovalRequestContext must be used within a ApprovalRequestProvider'
    );
  }
  return context;
};
