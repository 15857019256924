import { CookieAttributes } from 'js-cookie';
import { THEME_NAME } from 'types/utils';

export const isEnvDevelopment =
  !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'development';
const isEnvStaging = process.env.REACT_APP_ENV === 'staging';
const isEnvSandbox = process.env.REACT_APP_ENV === 'sandbox';
const isEnvProduction = process.env.REACT_APP_ENV === 'production';
export const isCdkEnvironment =
  process.env.REACT_APP_WHITELABEL_NAME === THEME_NAME.CDK;

const getDomain = (isEnvDevelopment: boolean, isCdkEnvironment: boolean) => {
  if (isEnvDevelopment) {
    return 'localhost';
  }

  if (isCdkEnvironment) {
    return '.vidmails.com';
  }

  return '.covideo.com';
};

export const cookieOptions: CookieAttributes = {
  domain: getDomain(isEnvDevelopment, isCdkEnvironment),
  path: '/',
  secure: false,
  sameSite: 'lax',
  expires: 7,
};

export const COOKIE_JWT = 'jwt';

type AtEnvProps = {
  defaultValue: string;
  staging?: string | undefined;
  sandbox?: string | undefined;
  development?: string | undefined;
  production?: string | undefined;
};

const atEnv = ({
  defaultValue,
  development,
  sandbox,
  staging,
  production,
}: AtEnvProps) => {
  if (isEnvDevelopment) {
    return !!development ? development : defaultValue;
  }
  if (isEnvStaging) {
    return !!staging ? staging : defaultValue;
  }
  if (isEnvSandbox) {
    return !!sandbox ? sandbox : defaultValue;
  }
  if (isEnvProduction) {
    return !!production ? production : defaultValue;
  }
};

export const apiExpress = atEnv({
  defaultValue: 'https://localhost:3000/api/',
  development: 'https://localhost:3000/api/',
  staging: 'https://api-staging.covideo.com/',
  sandbox: 'https://api-sandbox.covideo.com/',
  production: 'https://api.covideo.com/',
});
