import styled from 'styled-components/macro';
import { ROUTES } from 'app/routes/Routes';
import { useAuth } from 'lib/context/auth/AuthProvider';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ParagraphSmall } from 'lib/style/typography';
import { IoArrowBack } from 'react-icons/io5';
import { useCovideoTheme } from 'react-covideo-common';
import { useLogoUrl } from 'lib/hooks/useLogoUrl';
import { LogoWrapper } from '../../style/logo';

const BackIconWrapper = styled(Link)`
  position: absolute;
  left: 16px;
  @media (min-width: 768px) {
    .icon {
      display: none;
    }
  }
  ${({ theme }) => theme.mediaQueryMaxWidth.sm} {
    .icon {
      display: block;
    }
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  padding-top: 68px;
  margin-bottom: 190px;
  ${({ theme }) => theme.mediaQueryMaxWidth.sm} {
    margin-bottom: 70px;
    padding-top: 38px;
  }
`;

const SideWrapper = styled.div`
  position: absolute;
  right: 0;
  ${({ theme }) => theme.mediaQueryMaxWidth.sm} {
    top: 15px;
  }
`;

const ExtendedLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary[100]};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

const LogoImg = styled.img`
  width: 100%;
  height: auto;
`;

interface Props {
  extendStyle?: {
    container: React.CSSProperties | undefined;
  };
  imageUrl?: string;
}

export const MainNavigation = ({ extendStyle, imageUrl }: Props) => {
  const theme = useCovideoTheme();
  const { userData } = useAuth();
  const { pathname } = useLocation();
  const isLoginPage = pathname === '/login';
  const { logoUrl } = useLogoUrl();

  return (
    <Container style={extendStyle?.container}>
      {isLoginPage && (
        <BackIconWrapper to='/'>
          <IoArrowBack className='icon' />
        </BackIconWrapper>
      )}
      <LogoWrapper>
        {!!imageUrl ? (
          <LogoImg src={imageUrl} alt='companyLogo' />
        ) : (
          <img style={{ width: '100%' }} src={logoUrl} alt='logo' />
        )}
      </LogoWrapper>
      <SideWrapper>
        <ParagraphSmall color={theme.colors.neutral[60]}>
          {userData ? (
            <ExtendedLink to={ROUTES.logout.path}>
              (Logout {userData.username})
            </ExtendedLink>
          ) : (
            <>
              {!isLoginPage && (
                <>
                  <span>Are you the host? </span>
                  <ExtendedLink to={ROUTES.login.path}>Login</ExtendedLink>
                </>
              )}
            </>
          )}
        </ParagraphSmall>
      </SideWrapper>
    </Container>
  );
};
