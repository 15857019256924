import { useEffect } from 'react';
import { useAuth } from 'lib/context/auth/AuthProvider';

export const Logout = () => {
  const { logout } = useAuth();

  useEffect(() => {
    if (!logout) {
      return;
    }
    logout();
  }, [logout]);

  return null;
};
