import React from 'react';
import { Heading } from 'lib/style/typography';
import { MainContainer } from 'lib/style/layout';
import { SmallFormContainer } from 'lib/forms/style';
import { MainNavigation } from 'lib/components/navigation/MainNavigation';
import { LoginForm } from 'lib/forms/Login';
import { IoArrowBack } from 'react-icons/io5';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { MeetingInfo } from 'lib/components/info/MeetingInfo';
import { useCovideoTheme } from 'react-covideo-common';

const BackIconWrapper = styled(Link)`
  position: absolute;
  top: calc(32px + 12px);
  ${({ theme }) => theme.mediaQueryMaxWidth.sm} {
    .icon {
      display: none;
    }
  }
`;

export const Login = () => {
  const theme = useCovideoTheme();
  return (
    <MainContainer>
      <MainNavigation />
      <SmallFormContainer style={{ position: 'relative' }}>
        <BackIconWrapper to='/'>
          <IoArrowBack className='icon' color={theme.colors.black[100]} />
        </BackIconWrapper>

        <Heading
          color={theme.colors.black[100]}
          m='0 0 24px 0'
          textAlign='center'
        >
          Login
        </Heading>
        <LoginForm />
      </SmallFormContainer>
      <MeetingInfo />
    </MainContainer>
  );
};
