import React, { ReactNode, createContext, useContext, useEffect } from 'react';
import { useGetUserQuery } from 'lib/api/user/getUser';
import { useMutation } from 'react-query';
import Cookies from 'js-cookie';
import api from 'lib/api';
import { IAuthContext, ILoginData } from './types';
import { ROUTES } from 'app/routes/Routes';
import { COOKIE_JWT, cookieOptions } from 'lib/config';
import { useToastError } from 'lib/hooks';
import { LoadingIndicator } from 'lib/components/loader/Loader';
import * as Sentry from '@sentry/browser';

const authState = {
  userData: null,
  login: async () => {
    throw new Error('login function has not been implemented');
  },
  logout: () => {
    throw new Error('logout function has not been implemented');
  },
} as IAuthContext;

const AuthContext = createContext<IAuthContext>(authState);

function AuthProvider(props: { children: ReactNode }) {
  // Only enable the query once the token is set
  const { data: user = null, isLoading } = useGetUserQuery({
    enabled: !!Cookies.get(COOKIE_JWT),
  });

  const urlParams = new URLSearchParams(window.location.search);
  const urlJWT = urlParams.get('jwt');

  useEffect(() => {
    if (urlJWT) {
      // If JWT from URL exists, update the state and store it in cookies
      Cookies.set(COOKIE_JWT, urlJWT, cookieOptions);
      urlParams.delete('jwt');

      window.history.replaceState(null, '', `${window.location.pathname}`);

      // Reload the page after the URL update
      window.location.reload();
    }
  }, [urlJWT]);

  const { showError } = useToastError();

  const loginMutation = useMutation(
    async (values: ILoginData) => {
      const response = await api.post('/auth', values);
      const { data } = response;
      return data;
    },
    {
      onSuccess: data => {
        if (data?.accessToken) {
          Cookies.set(COOKIE_JWT, data.accessToken, cookieOptions);
        }
      },
    }
  );

  if (isLoading) {
    return <LoadingIndicator />;
  }

  const login = async (values: ILoginData) => {
    try {
      await loginMutation.mutateAsync(values);
      window.location.href = ROUTES.home.path;
    } catch (error) {
      showError(error, 'Invalid email or password');
    }
  };

  const logout = () => {
    Cookies.remove(COOKIE_JWT, cookieOptions);
    window.location.href = ROUTES.home.path;
  };

  Sentry.setUser({ username: user?.username, id: user?.id });

  const value = {
    userData: user,
    login,
    logout,
  };

  return <AuthContext.Provider value={value} {...props} />;
}

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
