import { useQuery } from 'react-query';
import { meetingKeys } from './meetingKeys';
import { MeetingRequest } from './types';
import api from '..';

const getMeetingApprovalRequests = async (
  meetingId: string
): Promise<MeetingRequest[]> => {
  const response = await api.get(`/live/${meetingId}/request`);
  const { data: approvalRequests } = response;
  return approvalRequests as MeetingRequest[];
};

export const useGetMeetingApprovalRequests = (
  meetingId: string,
  isMeetingHost: boolean
) => {
  return useQuery(
    meetingKeys.meetingRequests(),
    () => getMeetingApprovalRequests(meetingId || ''),
    {
      enabled: !!meetingId && isMeetingHost,
    }
  );
};
