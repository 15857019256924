interface IHost {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone1: string;
  phone2: string;
}
export interface IMeeting {
  meetingId: string;
  userId: number;
  customerId: number;
  email?: string;
  deliveryTime: string;
  deliveryUnixTimestamp: string | number | null;
  nextOccurrence?: number | null;
  duration: number;
  title: string;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  contactId?: string;
  leadId?: string;
  enableGuestScreenShare?: boolean;
  hostApproval?: boolean;
  meetingStatus?: MEETING_STATUS;
  host: IHost;
  landingImageUrl: string;
  maxParticipantCapacity: number;
  meetingMessages?: MeetingMessage[];
}

export enum MEETING_STATUS {
  STARTED = 'started',
  SCHEDULED = 'scheduled',
  FINISHED = 'finished',
  RECURRING = 'recurring',
}
export interface MeetingTime {
  meetingDuration: string;
  day: string;
}

export interface MeetingMessage {
  meetingMessageId: number;
  meetingId: string;
  userId: number;
  connectionId: string;
  nickname: string;
  text: string;
  createdAt: string;
}

export type SendMeetingMessageParams = {
  meetingId?: string;
  data: Pick<MeetingMessage, 'connectionId' | 'nickname' | 'text'>;
};

export enum APPROVAL_STATUS {
  requested = 'requested',
  approved = 'approved',
  denied = 'denied',
}

export interface MeetingRequest {
  meetingRequestId: number;
  meetingId: string;
  browserHash: string;
  nickname: string;
  approvalStatus: APPROVAL_STATUS;
  createdAt: string;
  updatedAt: string;
}

export type CreateMeetingRequestParams = {
  meetingId?: string;
  data: Pick<MeetingRequest, 'browserHash' | 'nickname'>;
};

export type ApproveMeetingRequestParams = Pick<
  MeetingRequest,
  'meetingId' | 'meetingRequestId'
>;

export type RemoveParticipantParams = {
  sessionId: string;
  connectionId: string;
};
