import { useQuery } from 'react-query';
import { meetingKeys } from './meetingKeys';
import { MeetingMessage } from './types';
import api from '..';

const getMeetingMessages = async (
  meetingId: string
): Promise<MeetingMessage[]> => {
  const response = await api.get(`/live/${meetingId}/messages`);
  const { data: messages } = response;
  return messages as MeetingMessage[];
};

export const useGetMeetingMessages = (meetingId?: string) => {
  return useQuery(
    meetingKeys.meetingMessages(),
    () => getMeetingMessages(meetingId || ''),
    {
      enabled: !!meetingId,
    }
  );
};
