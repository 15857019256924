import React, { createContext, memo, useContext } from 'react';
import { useState, useRef, MutableRefObject, useEffect } from 'react';
import {
  Connection,
  Device,
  ExceptionEvent,
  OpenVidu,
  Publisher,
  Session,
  SignalEvent,
  StreamEvent,
  StreamPropertyChangedEvent,
} from 'openvidu-browser';
import { useCreateSessionMutation } from 'lib/api/openvidu/createSession';
import { useCreateSessionConnectionMutation } from 'lib/api/openvidu/createSessionConnection';
import { errorToast } from 'lib/components/toasts/error';
import {
  getDataFromSubscriber,
  getRandomNickname,
  getUnknownProperty,
} from 'lib/utils/utils';
import {
  MAX_REMOTE_PARTICIPANTS_TO_PLAY_SOUNDS,
  PERMISSION_STATUS,
} from './const';
import { useToastError } from '../../hooks/useToastError';
import { DeviceSettings, Participant, SIGNAL } from 'lib/context/openvidu/type';
import { MULTI_LAYOUT } from './const';
import { useQueryClient } from 'react-query';
import { meetingKeys } from 'lib/api/meeting/meetingKeys';
import { useStartRecordingSessionMutation } from 'lib/api/openvidu/startRecordingSession';
import { useStopRecordingSessionMutation } from 'lib/api/openvidu/stopRecordingSession';
import { IRecording } from 'lib/api/openvidu/types';
import { ROOM, useRoom } from '../room/RoomProvider';
import { useSoundPlayer } from 'lib/hooks';
import { useMeeting } from '../meeting/MeetingProvider';
import { useRemoveParticipantMutation } from 'lib/api/openvidu/removeParticipant';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

interface UseOpenVidu {
  openViduRef: MutableRefObject<OpenVidu | null>;
  session: Session | null;
  remoteParticipants: Participant[];
  localParticipant: Participant;
  screenShareParticipant: Participant | undefined;
  permissionStatus: PERMISSION_STATUS;
  layout: MULTI_LAYOUT;
  isChatUnread: boolean;
  isScreenSharingDisabled: boolean;
  isLocalScreenSharing: boolean;
  isScreenShareLoading: boolean;
  isRecording: boolean;
  isRecordingLoading: boolean;
  isSessionLoading: boolean;
  speakers: Set<string>;
  availableDevices: Device[] | undefined;
  currentVideoDevice: Device | undefined;
  currentAudioDevice: Device | undefined;
  resolution: string | undefined;
  initCameraPreview: () => void;
  switchCamera: () => void;
  joinSession: (sessionId: string, nickname?: string) => Promise<void>;
  leaveSession: () => void;
  changeMicStatus: () => void;
  changeCameraStatus: () => void;
  changeDeviceSettings: (settings: DeviceSettings) => Promise<void>;
  changeLayout: (val: MULTI_LAYOUT) => void;
  sendSignal: (signalType: SIGNAL, data: unknown) => void;
  updateIsChatUnread: (val: boolean) => void;
  startScreenSharing: () => Promise<void>;
  stopScreenSharing: () => void;
  startRecording: () => Promise<void>;
  stopRecording: () => Promise<void>;
  updateLocalParticipant: (data: Partial<Participant>) => void;
  removeParticipant: (data: Participant) => Promise<void>;
}

const OpenViduContext = createContext({} as UseOpenVidu);

export const OpenViduProvider = memo(
  ({ children }: { children: React.ReactNode }) => {
    const { showError } = useToastError();
    const queryClient = useQueryClient();
    const { mutateAsync: createSession, isLoading: isLoadingCreateSession } =
      useCreateSessionMutation();
    const {
      mutateAsync: createSessionConnection,
      isLoading: isLoadingCreateSessionConnection,
    } = useCreateSessionConnectionMutation();
    const {
      mutateAsync: startRecordingSession,
      isLoading: isLoadingRecordingStart,
    } = useStartRecordingSessionMutation();
    const {
      mutateAsync: stopRecordingSession,
      isLoading: isLoadingRecordingStop,
    } = useStopRecordingSessionMutation();
    const { changeRoom, changeMeetingModal } = useRoom();
    const {
      playParticipantJoinedSound,
      playParticipantLeaveSound,
      playRecordingStartSound,
      playRecordingStopSound,
    } = useSoundPlayer();
    const { isMeetingHost, meeting } = useMeeting();
    const { mutateAsync: removeParticipantFromSession } =
      useRemoveParticipantMutation();
    const history = useHistory();

    const openViduRef = useRef<OpenVidu | null>(null);
    const openViduScreenShareRef = useRef<OpenVidu | null>(null);
    const currentSessionRef = useRef<Session | null>(null);
    const currentScreenShareSessionRef = useRef<Session | null>(null);
    const [layout, setLayout] = useState<MULTI_LAYOUT>(MULTI_LAYOUT.GALLERY);
    const [mirror, setMirror] = useState(true);
    const [localParticipant, setLocalParticipant] = useState<Participant>({
      connectionId: '',
      streamManager: undefined,
      audioActive: true,
      videoActive: true,
      nickname: '',
      type: 'local',
      isHost: false,
      isScreen: false,
      isRecording: false,
      hasException: false,
    });
    const [availableDevices, setAvailableDevices] = useState<
      Device[] | undefined
    >(undefined);
    const [currentVideoDevice, setCurrentVideoDevice] = useState<
      Device | undefined
    >(undefined);
    const [currentAudioDevice, setCurrentAudioDevice] = useState<
      Device | undefined
    >(undefined);
    const [resolution, setResolution] = useState<string | undefined>(undefined);
    const [screenShareParticipant, setScreenShareParticipant] =
      useState<Participant>();
    const [remoteParticipants, setRemoteParticipants] = useState<Participant[]>(
      []
    );
    const [permissionStatus, setPermissionStatus] = useState<PERMISSION_STATUS>(
      PERMISSION_STATUS.PROMPT
    );
    const [isChatUnread, setIsChatUnread] = useState(false);
    const [isLocalScreenSharing, setIsLocalScreenSharing] = useState(false);
    const [isScreenShareLoading, setIsScreenShareLoading] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [currentRecording, setCurrentRecording] = useState<IRecording>();
    const [isLoadingSession, setIsLoadingSession] = useState(false);
    const [speakers, setSpeakers] = useState<Set<string>>(new Set([]));

    useEffect(() => {
      let timeoutId: NodeJS.Timeout | undefined;
      if (screenShareParticipant) {
        timeoutId = setTimeout(() => {
          // postpone loading removal so stream video is ready
          setIsScreenShareLoading(false);
        }, 3500);
      }
      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    }, [screenShareParticipant]);

    useEffect(() => {
      const isRecordingVal =
        localParticipant.isRecording ||
        remoteParticipants.some(participant => participant.isRecording);
      setIsRecording(isRecordingVal);
      if (localParticipant.isRecording) {
        sendSignal(SIGNAL.userChanged, { isRecording: true });
      }
    }, [localParticipant.isRecording, remoteParticipants]);

    // TODO: remove dev logs
    useEffect(() => {
      console.log('remoteParticipants', remoteParticipants);
    }, [remoteParticipants]);
    useEffect(() => {
      console.log('localParticipant', localParticipant);
    }, [localParticipant]);

    const handleStreamCreatedEvent = (session: Session, event: StreamEvent) => {
      const subscriber = session.subscribe(event.stream, undefined);
      const subscriberData = getDataFromSubscriber(subscriber);
      const newParticipant: Participant = {
        streamManager: subscriber,
        isScreen: false,
        isRecording: false,
        hasException: false,
        connectionId: event.stream.connection.connectionId,
        type: 'remote',
        audioActive: event.stream.audioActive,
        videoActive: event.stream.videoActive,
        nickname: subscriberData?.nickname || '',
        isHost: subscriberData?.isHost || '',
      };
      if (event.stream.typeOfVideo === 'SCREEN') {
        setScreenShareParticipant({ ...newParticipant, isScreen: true });
        return;
      }
      setRemoteParticipants(prev => {
        const newRemoteParticipants = [...prev, newParticipant];
        if (
          newRemoteParticipants.length <= MAX_REMOTE_PARTICIPANTS_TO_PLAY_SOUNDS
        ) {
          playParticipantJoinedSound();
        }
        return newRemoteParticipants;
      });
    };

    const getLocalConnectionId = () => {
      let localConnectionId = '';
      setLocalParticipant(prev => {
        localConnectionId = prev.connectionId;
        return prev;
      });
      return localParticipant.connectionId || localConnectionId;
    };

    const handleStreamPropertyChangedEvent = (
      event: StreamPropertyChangedEvent
    ) => {
      const connectionId = event?.stream?.connection?.connectionId;
      const videoActive =
        event.reason === 'publishVideo' ? !!event?.newValue : undefined;
      const audioActive =
        event.reason === 'publishAudio' ? !!event?.newValue : undefined;
      updateRemoteParticipant(connectionId, {
        videoActive,
        audioActive,
      });
    };

    const handleStreamDestroyedEvent = (event: StreamEvent) => {
      setRemoteParticipants(prev => {
        const newRemoteParticipants = prev.filter(
          sub => sub.streamManager !== event.stream.streamManager
        );
        const isScreen = event?.stream?.typeOfVideo === 'SCREEN';
        if (
          !isScreen &&
          newRemoteParticipants.length <= MAX_REMOTE_PARTICIPANTS_TO_PLAY_SOUNDS
        ) {
          playParticipantLeaveSound();
        }
        return newRemoteParticipants;
      });
      setScreenShareParticipant(prev =>
        prev?.streamManager === event.stream.streamManager ? undefined : prev
      );
    };

    const handleExceptionEvent = (event: ExceptionEvent) => {
      Sentry.captureException(event);
      console.log('OpenVidu exception', event);
      // connection with exception
      if (event.name === 'NO_STREAM_PLAYING_EVENT') {
        const exceptionOrigin = event?.origin;
        if (exceptionOrigin) {
          setRemoteParticipants(prevParticipants => {
            const index = prevParticipants.findIndex(
              participant => participant.streamManager === exceptionOrigin
            );
            if (index === -1) {
              return prevParticipants;
            }
            // Create the updated participant object
            const updatedParticipant: Participant = {
              ...prevParticipants[index],
              hasException: true,
            };

            return [
              ...prevParticipants.slice(0, index),
              updatedParticipant,
              ...prevParticipants.slice(index + 1),
            ];
          });
        }
      }
    };

    const subscribeToSessionEvents = (session: Session) => {
      session.on('streamCreated', async event => {
        handleStreamCreatedEvent(session, event);
      });

      session.on('streamPropertyChanged', event => {
        handleStreamPropertyChangedEvent(event);
      });

      session.on('streamDestroyed', event => {
        handleStreamDestroyedEvent(event);
      });

      session.on('recordingStarted', () => {
        playRecordingStartSound();
      });

      session.on('sessionDisconnected', event => {
        if (
          event.reason === 'forceDisconnectByServer' ||
          event.reason === 'forceDisconnectByUser'
        ) {
          errorToast({ title: 'Kicked out by Meeting Host' });
          leaveSession();
          history.push(`/${meeting!.meetingId}/removed`);
        }
      });

      session.on('recordingStopped', () => {
        playRecordingStopSound();
      });

      session.on('exception', exception => {
        handleExceptionEvent(exception);
      });

      session.on('publisherStartSpeaking', event => {
        const connectionId = event?.connection?.connectionId;
        if (!connectionId) {
          return;
        }
        setSpeakers(prev => new Set(prev).add(connectionId));
      });

      session.on('publisherStopSpeaking', event => {
        const connectionId = event?.connection?.connectionId;
        if (!connectionId) {
          return;
        }
        setSpeakers(prev => {
          const newSet = new Set(prev);
          newSet.delete(connectionId);
          return newSet;
        });
      });

      session.on(`signal:${SIGNAL.userChanged}`, (event: SignalEvent) => {
        const connectionId = event?.from?.connectionId;
        const data = JSON.parse(event?.data || '{}');
        if (!connectionId) {
          return;
        }
        updateRemoteParticipant(connectionId, data);
      });

      session.on(`signal:${SIGNAL.newMessage}`, (event: SignalEvent) => {
        const connectionId = event?.from?.connectionId;
        if (connectionId === localParticipant.connectionId) {
          return;
        }
        updateIsChatUnread(true);
        queryClient.invalidateQueries(meetingKeys.meetingMessages());
      });

      session.on(
        `signal:${SIGNAL.screenShareLoading}`,
        (event: SignalEvent) => {
          const { isScreenShareLoading } = JSON.parse(event?.data || '{}');
          setIsScreenShareLoading(!!isScreenShareLoading);
        }
      );

      session.on(`signal:${SIGNAL.endMeetingForAll}`, () => {
        if (currentSessionRef.current) {
          currentSessionRef.current.disconnect();
        }
        if (currentScreenShareSessionRef.current) {
          currentScreenShareSessionRef.current.disconnect();
        }
        changeRoom(ROOM.FINISHED);
        changeMeetingModal(null);
      });

      session.on(`signal:${SIGNAL.removeParticipant}`, (event: SignalEvent) => {
        const connectionId = JSON.parse(event?.data || '{}')?.connectionId;
        if (!connectionId) {
          return;
        }
        removeRemoteParticipant(connectionId);
      });
    };

    const getToken = async (customSessionId: string) => {
      const createSessionResponse = await createSession({
        customSessionId,
      });
      const { sessionId } = createSessionResponse;
      const createSessionConnectionResponse = await createSessionConnection({
        sessionId,
      });
      const { token } = createSessionConnectionResponse;
      return token;
    };

    const getScreenShareToken = async (customSessionId: string) => {
      const createSessionConnectionResponse = await createSessionConnection({
        sessionId: customSessionId,
        isScreen: true,
      });
      const { token } = createSessionConnectionResponse;
      return token;
    };

    const initCameraPreview = async () => {
      // init for camera
      if (!openViduRef.current) {
        openViduRef.current = new OpenVidu();
        openViduRef.current.enableProdMode(); // disable logs
      }
      // init for screen
      if (!openViduScreenShareRef.current) {
        openViduScreenShareRef.current = new OpenVidu();
        openViduScreenShareRef.current.enableProdMode(); // disable logs
      }
      try {
        const previewPublisher = await openViduRef.current.initPublisherAsync(
          undefined,
          {
            insertMode: 'REPLACE',
            resolution,
            audioSource: undefined,
            videoSource: undefined,
            mirror: true,
            publishAudio: localParticipant.audioActive,
            publishVideo: localParticipant.videoActive,
          }
        );
        const availableInputDevices = await openViduRef.current.getDevices();
        const videoDevices = availableInputDevices.filter(
          device => device.kind === 'videoinput'
        );
        const currentVideoDeviceId = previewPublisher.stream
          .getMediaStream()
          .getVideoTracks()[0]
          .getSettings().deviceId;
        const newVideoDevice = videoDevices.find(
          device => device.deviceId === currentVideoDeviceId
        );
        const audioDevices = availableInputDevices.filter(
          device => device.kind === 'audioinput'
        );
        const currentAudioDeviceId = previewPublisher.stream
          .getMediaStream()
          .getAudioTracks()[0]
          .getSettings().deviceId;
        const newAudioDevice = audioDevices.find(
          device => device.deviceId === currentAudioDeviceId
        );
        setMirror(true);
        setLocalParticipant(prev => ({
          ...prev,
          streamManager: previewPublisher,
          audioActive: localParticipant.audioActive,
          videoActive: localParticipant.videoActive,
          isHost: isMeetingHost,
        }));
        setCurrentAudioDevice(newAudioDevice);
        setCurrentVideoDevice(newVideoDevice);
        setAvailableDevices(availableInputDevices);
        setPermissionStatus(PERMISSION_STATUS.GRANTED);
      } catch (error) {
        setPermissionStatus(PERMISSION_STATUS.DENIED);
      }
    };

    const joinSession = async (customSessionId: string, nickname?: string) => {
      if (
        !openViduRef.current ||
        !localParticipant?.streamManager ||
        !customSessionId
      ) {
        errorToast({ title: 'Failed to join Meeting' });
        return;
      }
      setIsLoadingSession(true);
      localParticipant.streamManager.stream
        .getMediaStream()
        .getTracks()
        .forEach(track => track.stop());
      const newPublisher = await openViduRef.current.initPublisherAsync(
        undefined,
        {
          insertMode: 'REPLACE',
          resolution,
          audioSource: currentAudioDevice?.deviceId,
          videoSource: currentVideoDevice?.deviceId,
          mirror,
          publishAudio: localParticipant.audioActive,
          publishVideo: localParticipant.videoActive,
        }
      );
      const session = openViduRef.current.initSession();
      subscribeToSessionEvents(session);
      const sessionNickname =
        nickname || localParticipant.nickname || getRandomNickname();
      const token = await getToken(customSessionId);
      await session.connect(token, {
        nickname: sessionNickname,
        audioActive: localParticipant.audioActive,
        videoActive: localParticipant.videoActive,
        isHost: isMeetingHost,
      });
      session.publish(newPublisher);
      currentSessionRef.current = session;
      setLocalParticipant(prev => ({
        ...prev,
        nickname: sessionNickname,
        connectionId: session.connection.connectionId,
        streamManager: newPublisher,
      }));
      setIsLoadingSession(false);
      changeRoom(ROOM.SESSION);
    };

    const leaveSession = () => {
      if (!currentSessionRef?.current) {
        return;
      }
      setIsLoadingSession(true);
      if (currentSessionRef?.current) {
        currentSessionRef.current.disconnect();
      }
      currentSessionRef.current = null;
      openViduRef.current = null;
      setRemoteParticipants([]);
      setLocalParticipant(prev => ({
        ...prev,
        streamManager: undefined,
        connectionId: '',
      }));
      stopScreenSharing();
      setIsLoadingSession(false);
      changeMeetingModal(null);
    };

    const removeParticipant = async (participant: Participant) => {
      if (
        !currentSessionRef?.current?.sessionId ||
        !participant?.connectionId
      ) {
        return;
      }
      try {
        await removeParticipantFromSession({
          sessionId: currentSessionRef?.current?.sessionId,
          connectionId: participant.connectionId,
        });
        removeRemoteParticipant(participant.connectionId);
        sendSignal(SIGNAL.removeParticipant, {
          connectionId: participant.connectionId,
        });
      } catch (error) {
        showError(error, 'Failed to remove participant.');
      }
    };

    const switchCamera = async () => {
      try {
        if (!openViduRef.current || !localParticipant.streamManager) {
          return;
        }
        const availableDevices = await openViduRef.current.getDevices();
        const videoDevices = (availableDevices || []).filter(
          device => device.kind === 'videoinput'
        );
        const otherVideoDevices = (videoDevices || []).filter(
          device => device.deviceId !== currentVideoDevice?.deviceId
        );
        if (
          !videoDevices ||
          videoDevices.length < 2 ||
          otherVideoDevices.length === 0
        ) {
          console.log('No other device available to switch to');
          return;
        }
        const newDevice = otherVideoDevices[0];
        localParticipant.streamManager.stream
          .getMediaStream()
          .getTracks()
          .forEach(track => track.stop());
        const newPublisher = await openViduRef.current.initPublisherAsync(
          undefined,
          {
            insertMode: 'REPLACE',
            resolution,
            audioSource: undefined,
            mirror: !mirror,
            videoSource: newDevice.deviceId,
            publishAudio: localParticipant.audioActive,
            publishVideo: localParticipant.videoActive,
          }
        );
        if (currentSessionRef?.current) {
          await currentSessionRef.current.unpublish(
            localParticipant.streamManager as Publisher
          );
          await currentSessionRef.current.publish(newPublisher);
        }
        setMirror(prev => !prev);
        setLocalParticipant(prev => ({
          ...prev,
          streamManager: newPublisher,
        }));
        setAvailableDevices(availableDevices);
        setCurrentVideoDevice(newDevice);
      } catch (error) {
        showError(error, 'Unable to change camera.');
      }
    };

    const changeDeviceSettings = async ({
      audioDeviceId,
      videoDeviceId,
      resolution,
    }: DeviceSettings) => {
      try {
        if (!openViduRef.current || !localParticipant.streamManager) {
          return;
        }
        const availableDevices = await openViduRef.current.getDevices();
        const videoDevices = (availableDevices || []).filter(
          device => device.kind === 'videoinput'
        );
        const newVideoDevice = (videoDevices || []).find(
          device => device.deviceId === videoDeviceId
        );
        const audioDevices = (availableDevices || []).filter(
          device => device.kind === 'audioinput'
        );
        const newAudioDevice = (audioDevices || []).find(
          device => device.deviceId === audioDeviceId
        );
        localParticipant.streamManager.stream
          .getMediaStream()
          .getTracks()
          .forEach(track => track.stop());
        const newPublisher = await openViduRef.current.initPublisherAsync(
          undefined,
          {
            insertMode: 'REPLACE',
            mirror,
            videoSource: newVideoDevice?.deviceId,
            audioSource: newAudioDevice?.deviceId,
            publishAudio: localParticipant.audioActive,
            publishVideo: localParticipant.videoActive,
            resolution,
          }
        );
        if (currentSessionRef?.current) {
          await currentSessionRef.current.unpublish(
            localParticipant.streamManager as Publisher
          );
          await currentSessionRef.current.publish(newPublisher);
        }
        setAvailableDevices(availableDevices);
        setCurrentAudioDevice(newAudioDevice);
        setCurrentVideoDevice(newVideoDevice);
        setResolution(resolution);
        setLocalParticipant(prev => ({
          ...prev,
          streamManager: newPublisher,
        }));
      } catch (error) {
        showError(error, 'Unable to apply new settings.');
      }
    };

    const changeMicStatus = () => {
      setLocalParticipant(prev => {
        const newAudioActive = !prev.audioActive;
        try {
          if (prev.streamManager) {
            (prev.streamManager as Publisher).publishAudio(newAudioActive);
            return { ...prev, audioActive: newAudioActive };
          }
          return prev;
        } catch (error) {
          showError(error, 'Failed to toggle microphone.');
          return prev;
        }
      });
    };

    const changeCameraStatus = () => {
      setLocalParticipant(prev => {
        const newVideoActive = !prev.videoActive;
        try {
          if (prev.streamManager) {
            (prev.streamManager as Publisher).publishVideo(newVideoActive);
            return { ...prev, videoActive: newVideoActive };
          }
          return prev;
        } catch (error) {
          showError(error, 'Failed to toggle camera.');
          return prev;
        }
      });
    };

    const startScreenSharing = async () => {
      if (
        !openViduScreenShareRef.current ||
        !currentSessionRef?.current?.sessionId
      ) {
        return;
      }
      sendSignal(SIGNAL.screenShareLoading, { isScreenShareLoading: true });
      setIsLocalScreenSharing(true);
      try {
        const newScreenSharePublisher =
          await openViduScreenShareRef.current.initPublisherAsync(undefined, {
            videoSource: 'screen',
            audioSource: false,
            publishAudio: false,
            publishVideo: true,
          });
        newScreenSharePublisher.once('accessAllowed', async () => {
          if (
            !openViduScreenShareRef.current ||
            !currentSessionRef?.current?.sessionId
          ) {
            return;
          }
          const screenShareSession =
            openViduScreenShareRef.current.initSession();
          try {
            const screenShareToken = await getScreenShareToken(
              currentSessionRef?.current?.sessionId
            );
            await screenShareSession.connect(screenShareToken, {
              nickname: localParticipant.nickname,
              audioActive: true,
              videoActive: true,
              isHost: isMeetingHost,
              isScreen: true,
            });
            currentScreenShareSessionRef.current = screenShareSession;
            newScreenSharePublisher.stream
              .getMediaStream()
              .getVideoTracks()[0]
              .addEventListener('ended', () => {
                // User pressed the "Stop sharing" button
                screenShareSession.unpublish(newScreenSharePublisher);
                screenShareSession.disconnect();
                stopScreenSharing();
              });
            screenShareSession.publish(newScreenSharePublisher);
          } catch (error) {
            showError(
              error,
              'Something went wrong when starting screen sharing.'
            );
            setIsLocalScreenSharing(false);
            setScreenShareParticipant(undefined);
            sendSignal(SIGNAL.screenShareLoading, {
              isScreenShareLoading: false,
            });
          }
        });
        newScreenSharePublisher.once('accessDenied', () => {
          stopScreenSharing();
          sendSignal(SIGNAL.screenShareLoading, {
            isScreenShareLoading: false,
          });
        });
      } catch (error) {
        const openViduErrorName = getUnknownProperty(error, 'name');
        if (openViduErrorName !== 'SCREEN_CAPTURE_DENIED') {
          showError(
            error,
            'Something went wrong when initiating screen sharing.'
          );
        }
        setIsLocalScreenSharing(false);
        setScreenShareParticipant(undefined);
        sendSignal(SIGNAL.screenShareLoading, { isScreenShareLoading: false });
      }
    };

    const stopScreenSharing = async () => {
      if (currentScreenShareSessionRef.current) {
        currentScreenShareSessionRef.current.disconnect();
      }
      currentScreenShareSessionRef.current = null;
      setIsLocalScreenSharing(false);
      setScreenShareParticipant(undefined);
    };

    const startRecording = async () => {
      if (!currentSessionRef?.current?.sessionId) {
        return;
      }
      const recording = await startRecordingSession({
        sessionId: currentSessionRef?.current?.sessionId,
      });
      sendSignal(SIGNAL.userChanged, { isRecording: true });
      setCurrentRecording(recording);
      setLocalParticipant(prev => ({ ...prev, isRecording: true }));
    };

    const stopRecording = async () => {
      if (!currentSessionRef?.current?.sessionId || !currentRecording?.id) {
        return;
      }
      await stopRecordingSession({
        sessionId: currentSessionRef?.current?.sessionId,
        recordingId: currentRecording.id,
      });
      sendSignal(SIGNAL.userChanged, { isRecording: false });
      setCurrentRecording(undefined);
      setLocalParticipant(prev => ({ ...prev, isRecording: false }));
    };

    const changeLayout = (newLayout: MULTI_LAYOUT) => setLayout(newLayout);

    const sendSignal = async (
      signalType: SIGNAL,
      data: unknown,
      connection?: Connection[]
    ) => {
      if (!currentSessionRef?.current) {
        return;
      }
      const signalOptions = {
        data: JSON.stringify(data),
        to: connection,
        type: signalType,
      };
      await currentSessionRef.current.signal(signalOptions);
    };

    const updateRemoteParticipant = (
      connectionId: string,
      data: Partial<Participant>
    ) => {
      if (!connectionId || connectionId === getLocalConnectionId()) {
        return;
      }
      setRemoteParticipants(prevParticipants => {
        // Find the index of the participant to be updated
        const index = prevParticipants.findIndex(
          participant => participant.connectionId === connectionId
        );

        // If the participant isn't found, return the unchanged array
        if (index === -1) {
          return prevParticipants;
        }

        // Create the updated participant object
        const updatedParticipant: Participant = {
          ...prevParticipants[index],
          audioActive:
            data.audioActive !== undefined
              ? data.audioActive
              : prevParticipants[index].audioActive,
          videoActive:
            data.videoActive !== undefined
              ? data.videoActive
              : prevParticipants[index].videoActive,
          nickname:
            data.nickname !== undefined
              ? data.nickname
              : prevParticipants[index].nickname,
          isRecording:
            data.isRecording !== undefined
              ? data.isRecording
              : prevParticipants[index].isRecording,
        };

        return [
          ...prevParticipants.slice(0, index),
          updatedParticipant,
          ...prevParticipants.slice(index + 1),
        ];
      });
    };

    const removeRemoteParticipant = (connectionId: string) => {
      setRemoteParticipants(prev =>
        prev.filter(participant => participant.connectionId !== connectionId)
      );
    };

    const updateLocalParticipant = (data: Partial<Participant>) => {
      setLocalParticipant(prev => {
        const updatedParticipant: Participant = {
          ...prev,
          nickname: data.nickname !== undefined ? data.nickname : prev.nickname,
        };
        return updatedParticipant;
      });
    };

    const updateIsChatUnread = (val: boolean) => setIsChatUnread(val);

    const isScreenSharingDisabled =
      (!!screenShareParticipant && !isLocalScreenSharing) ||
      isLoadingCreateSession ||
      isLoadingCreateSessionConnection ||
      isScreenShareLoading;

    const value: UseOpenVidu = {
      openViduRef,
      session: currentSessionRef.current,
      layout,
      permissionStatus,
      localParticipant,
      remoteParticipants,
      isChatUnread,
      startScreenSharing,
      stopScreenSharing,
      screenShareParticipant,
      isLocalScreenSharing,
      isSessionLoading: isLoadingSession,
      isRecording,
      isRecordingLoading: isLoadingRecordingStart || isLoadingRecordingStop,
      initCameraPreview,
      switchCamera,
      joinSession,
      leaveSession,
      changeMicStatus,
      changeCameraStatus,
      changeDeviceSettings,
      changeLayout,
      sendSignal,
      updateIsChatUnread,
      isScreenSharingDisabled,
      isScreenShareLoading,
      startRecording,
      stopRecording,
      updateLocalParticipant,
      removeParticipant,
      speakers,
      availableDevices,
      currentAudioDevice,
      currentVideoDevice,
      resolution,
    };

    return (
      <OpenViduContext.Provider value={value}>
        {children}
      </OpenViduContext.Provider>
    );
  }
);

export const useOpenViduProvider = () => {
  const context = useContext(OpenViduContext);
  if (!context) {
    throw new Error(
      'useOpenViduProvider must be used within a OpenViduContext'
    );
  }
  return context;
};
