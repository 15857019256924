import React from 'react';
import { CustomModal } from '../../../../../lib/components/modal/main';
import { Flex } from 'lib/style/layout';
import styled from 'styled-components/macro';
import { useOpenViduProvider } from 'lib/context/openvidu/OpenViduProvider';
import { Button } from 'react-covideo-common';

type Props = {
  handleClose: () => void;
};

const Body = styled.div`
  margin-bottom: 35px;
`;

const RecordMeetingModal = ({ handleClose }: Props) => {
  const { isRecording, stopRecording, startRecording } = useOpenViduProvider();

  const handleRecording = () => {
    if (isRecording) {
      stopRecording();
    }

    if (!isRecording) {
      startRecording();
    }
    handleClose();
  };

  return (
    <CustomModal
      closeModal={handleClose}
      headerText={`${isRecording ? `Stop` : `Start`} Recording`}
      style={{
        content: {
          width: '592px',
        },
      }}
    >
      <Body>
        {isRecording
          ? 'Do you want to stop recording?'
          : 'Do you want to start recording?\nAll participants will be notified.'}
      </Body>
      <Flex gap='12px' alignItems='center' justifyContent='flex-end'>
        <Button variant='secondary' onClick={handleClose} text='No, Go Back' />
        <Button
          variant='red'
          onClick={handleRecording}
          text={`${isRecording ? `Stop` : `Start`} Recording`}
        />
      </Flex>
    </CustomModal>
  );
};

export default RecordMeetingModal;
