import React from 'react';
import { MdInfo } from 'react-icons/md';
import { toast } from 'react-toastify';

import { BaseNotificationBody } from './base';
import { CloseToastIcon } from './close-icon';
import { ToastProps } from './types';

const SuccessNotification = (props: ToastProps) => {
  return (
    <BaseNotificationBody {...props}>
      <MdInfo size={26} style={{ minWidth: 26, maxWidth: 26 }} />
    </BaseNotificationBody>
  );
};

export const infoToast = (props: ToastProps) =>
  toast(<SuccessNotification {...props} />, {
    progressStyle: { background: 'transparent' },
    position: props.position || 'top-right',
    closeButton: CloseToastIcon,
  });
