import React from 'react';
import { MdWarning } from 'react-icons/md';
import { toast } from 'react-toastify';
import { BaseNotificationBody } from './base';
import { ToastProps } from './types';
import { CloseToastIcon } from './close-icon';
import { useCovideoTheme } from 'react-covideo-common';

const ErrorNotification = (props: ToastProps) => {
  const theme = useCovideoTheme();
  return (
    <BaseNotificationBody {...props}>
      <MdWarning
        color={theme.colors.danger[100]}
        size={26}
        style={{ minWidth: 26, maxWidth: 26 }}
      />
    </BaseNotificationBody>
  );
};

export const errorToast = (props: ToastProps) =>
  toast(<ErrorNotification {...props} />, {
    progressStyle: { background: 'transparent' },
    position: props.position || 'top-right',
    closeButton: <CloseToastIcon {...props} />,
  });
