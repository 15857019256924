import React from 'react';
import { MdCheck } from 'react-icons/md';
import { toast } from 'react-toastify';

import { BaseNotificationBody } from './base';
import { CloseToastIcon } from './close-icon';
import { ToastProps } from './types';

const SuccessNotification = (props: ToastProps) => {
  return (
    <BaseNotificationBody {...props}>
      <MdCheck
        color='rgba(30, 164, 95, 1)'
        size={26}
        style={{ minWidth: 26, maxWidth: 26 }}
      />
    </BaseNotificationBody>
  );
};

export const successToast = (props: ToastProps) =>
  toast(<SuccessNotification {...props} />, {
    progressStyle: { background: 'transparent' },
    position: props.position || 'top-right',
    closeButton: <CloseToastIcon {...props} />,
  });
