import React from 'react';
import styled, { css } from 'styled-components/macro';
import { SETTINGS_VALUES } from './const';
interface Items {
  id: SETTINGS_VALUES;
  label: string;
  icon: any;
  value: SETTINGS_VALUES;
}
interface Props {
  items: Items[];
  setActiveTab: React.Dispatch<React.SetStateAction<SETTINGS_VALUES>>;
  activeTab: SETTINGS_VALUES;
}

const TabsWrapper = styled.div`
  padding-bottom: 8px;
  margin-bottom: 24px;

  display: flex;
  border-bottom: 1px solid #eeeff2;
  width: 100%;
`;

const TabItem = styled.p<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  gap: 6px;
  padding-right: 16px;
  padding-left: 16px;
  position: relative;
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.primary[100] : theme.colors.neutral[60]};

  ${({ isActive }) =>
    isActive &&
    css`
      :after {
        content: '';
        height: 4px;
        position: absolute;
        width: 100%;
        background: ${({ theme }) => theme.colors.primary[100]};
        bottom: -8px;
      }
    `}
`;

export const Tabs = (props: Props) => {
  const { items, setActiveTab } = props;
  return (
    <TabsWrapper>
      {items.map((item: Items) => (
        <TabItem
          key={item.id}
          onClick={() => setActiveTab(item.value)}
          isActive={props.activeTab === item.value}
        >
          {item?.icon} {item.label}
        </TabItem>
      ))}
    </TabsWrapper>
  );
};
