import React, { createContext, memo, useContext, useState } from 'react';

interface IRoomContext {
  room: ROOM;
  meetingModal: MeetingModal;
  changeRoom: (room: ROOM) => void;
  changeMeetingModal: (meetingModal: MeetingModal) => void;
}

export enum ROOM {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  SCHEDULED = 'SCHEDULED',
  FINISHED = 'FINISHED',
  LOBBY = 'LOBBY',
  SESSION = 'SESSION',
}

export enum MEETING_MODAL {
  LEAVE = 'leave',
  INFO = 'info',
  SETTINGS = 'settings',
  LAYOUT = 'layout',
  RECORDING = 'recording',
  SCREEN_SHARING = 'screen-sharing',
}

type MeetingModal = MEETING_MODAL | null;

const RoomContext = createContext({} as IRoomContext);

export const RoomProvider = memo(
  ({ children }: { children: React.ReactNode }) => {
    const [room, setRoom] = useState<ROOM>(ROOM.LOADING);

    const [meetingModal, setMeetingModal] = useState<MeetingModal>(null);

    const changeRoom = (room: ROOM) => setRoom(room);

    const changeMeetingModal = (meetingModal: MeetingModal) =>
      setMeetingModal(meetingModal);

    const values: IRoomContext = {
      room,
      meetingModal,
      changeRoom,
      changeMeetingModal,
    };

    return (
      <RoomContext.Provider value={values}>{children}</RoomContext.Provider>
    );
  }
);

export const useRoom = () => {
  const context = useContext(RoomContext);
  if (context === undefined) {
    throw new Error('useRoom must be used within a RoomProvider');
  }
  return context;
};
