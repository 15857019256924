import React from 'react';
import styled from 'styled-components/macro';

const LabelText = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: ${({ theme }) => theme.colors.neutral[60]};
`;

interface Props {
  label: string;
  htmlFor: string;
}

export const Label = ({ htmlFor, label }: Props) => {
  return <LabelText htmlFor={htmlFor}>{label}</LabelText>;
};
