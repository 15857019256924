import { AxiosError } from 'axios';
import { useToastError } from 'lib/hooks';
import { useMutation, useQueryClient } from 'react-query';
import api from '..';
import { meetingKeys } from './meetingKeys';
import { IMeeting } from './types';

const updateMeetingToFinished = async (meetingId: string) => {
  const response = await api.patch(`/live/${meetingId}/finished`);
  if (!response.data) {
    throw new Error('No data found');
  }
  return response.data;
};

export const useUpdateMeetingToFinishedMutation = () => {
  const { showError } = useToastError();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (meetingId: string) => updateMeetingToFinished(meetingId),
    onSuccess: (response: IMeeting) => {
      queryClient.invalidateQueries(meetingKeys.detail(response.meetingId));
    },
    onError: (err: AxiosError) => {
      showError(err, 'Something went wrong when finishing meeting.');
    },
  });
};
