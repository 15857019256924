import { useMeetingRequestContext } from 'lib/context/meetingRequest/MeetingRequestProvider';
import { useMeeting } from 'lib/context/meeting/MeetingProvider';
import React from 'react';
import styled from 'styled-components/macro';
import { ModalMeetingRequests } from '../modals/meeting-requests';
import { ParagraphBold } from 'lib/style/typography';
import { getInitials } from 'lib/utils/utils';

const Wrapper = styled.div`
  position: absolute;
  bottom: calc(88px + 32px);
  right: 16px;
  background: white;
  padding: 12px 16px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  z-index: 1;
`;

const Bubbles = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0;
`;

const Bubble = styled.div`
  font-size: 18px;
  line-height: 1;
  margin: 0;
  font-style: normal;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral[100]};
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.neutral[20]};
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  margin-right: -8px;
`;

export const WaitingApproval = () => {
  const { isMeetingHost } = useMeeting();
  const { requests, showApproveRequestsModal, updateShowApproveRequestsModal } =
    useMeetingRequestContext();
  if (!requests.length || !isMeetingHost) {
    return null;
  }
  return (
    <>
      <Wrapper onClick={() => updateShowApproveRequestsModal(true)}>
        <ParagraphBold>Waiting Approval</ParagraphBold>
        <Bubbles>
          {requests.map(request => (
            <Bubble key={request.meetingRequestId}>
              {getInitials(request.nickname)}
            </Bubble>
          ))}
        </Bubbles>
      </Wrapper>
      {showApproveRequestsModal && (
        <ModalMeetingRequests
          onModalClose={() => updateShowApproveRequestsModal(false)}
        />
      )}
    </>
  );
};
