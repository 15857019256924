import React, { RefObject } from 'react';
import styled from 'styled-components/macro';
import { Label } from './Label';

const InputWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white[100]};
  display: flex;
  flex-direction: column;
  gap: 8px;

  .error {
    border-color: red;
  }
`;

const InputStyles = styled.input`
  height: 100%;

  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.neutral[20]};
  border-radius: 6px;
  width: 100%;
  padding: 10px 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  outline: none;

  color: ${({ theme }) => theme.colors.black[100]};

  ::placeholder {
    color: ${({ theme }) => theme.colors.neutral[60]};
  }
  :focus {
    border-color: ${({ theme }) => theme.colors.primary[100]};
  }
  :hover {
    border-color: ${({ theme }) => theme.colors.primary[100]};
  }
  :disabled {
    background: ${({ theme }) => theme.colors.neutral[40]};
  }
`;

interface Props {
  name: string;
  label?: string;
  placeholder: string;
  type: 'text' | 'email' | 'password';
  isError: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  innerRef?: RefObject<HTMLInputElement>;
  maxLength?: number;
}

export const TextInput = (props: Props) => {
  return (
    <InputWrapper>
      {props.label && <Label htmlFor={props.name} label={props.label} />}
      <InputStyles
        {...props}
        className={props.isError ? 'error' : ''}
        id={props.name}
        ref={props.innerRef}
      />
    </InputWrapper>
  );
};
