export enum MULTI_LAYOUT {
  GALLERY = 'GALLERY',
  SIDEBAR = 'SIDEBAR',
}

enum VIDEO_RESOLUTION_WIDTH {
  LOW = 320,
  STANDARD = 640,
  HIGH = 1280,
}
enum VIDEO_RESOLUTION_HEIGHT {
  LOW = 240,
  STANDARD = 480,
  HIGH = 720,
}

const VIDEO_RESOLUTION = {
  LOW: `${VIDEO_RESOLUTION_WIDTH.LOW}x${VIDEO_RESOLUTION_HEIGHT.LOW}`,
  STANDARD: `${VIDEO_RESOLUTION_WIDTH.STANDARD}x${VIDEO_RESOLUTION_HEIGHT.STANDARD}`,
  HIGH: `${VIDEO_RESOLUTION_WIDTH.HIGH}x${VIDEO_RESOLUTION_HEIGHT.HIGH}`,
};

export const VIDEO_RESOLUTION_OPTIONS = [
  {
    value: VIDEO_RESOLUTION.LOW,
    label: 'Low',
  },
  {
    value: VIDEO_RESOLUTION.STANDARD,
    label: 'Standard',
  },
  {
    value: VIDEO_RESOLUTION.HIGH,
    label: 'High',
  },
];

export enum PERMISSION_STATUS {
  GRANTED = 'granted',
  DENIED = 'denied',
  PROMPT = 'prompt',
}

export const MAX_REMOTE_PARTICIPANTS_TO_PLAY_SOUNDS = 9;
