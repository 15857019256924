import { createGlobalStyle } from 'styled-components/macro';

const GlobalStyles = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    font-family: 'Work Sans', sans-serif;
  }

  body {
    margin: 0; 
  }

  input,
  button,
  textarea,
  select {
    font-family: inherit;
  }
  .ReactModal__Content{
    @media (max-width: 640px) {
        width: calc(100% - 32px)!important;
    }
  }
`;

export default GlobalStyles;
