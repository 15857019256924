import React from 'react';
import styled from 'styled-components/macro';
import { useMeeting } from 'lib/context/meeting/MeetingProvider';
import { IoMdMic, IoMdMicOff } from 'react-icons/io';
import {
  BsCameraVideoFill,
  BsCameraVideoOffFill,
  BsRecord2,
} from 'react-icons/bs';
import { IoMdCall } from 'react-icons/io';
import { useOpenViduProvider } from 'lib/context/openvidu/OpenViduProvider';
import { IoMdChatboxes } from 'react-icons/io';
import { NofificationIndicator } from '../chat/NotificationIndicator';
import { useChat } from 'lib/context/chat/ChatProvider';
import { Spinner } from 'lib/components/loader/Spinner';
import LeaveMeetingModal from '../modals/leave-meeting';
import RecordMeetingModal from '../modals/record-meeting';
import { MEETING_MODAL, useRoom } from 'lib/context/room/RoomProvider';
import { SHOW_MOBILE_CONTROLS_MAX } from '../../const';
import { MdOutlineFlipCameraAndroid, MdStop } from 'react-icons/md';
import { Button } from 'react-covideo-common';

const ControlsContainer = styled.div`
  display: none;
  justify-content: space-around;
  position: absolute;
  z-index: 2;
  bottom: 16px;
  width: 100%;
  justify-content: center;
  gap: 16px;
  margin: 0 auto;
  ${({ theme }) => theme.mediaQueryMaxWidth[SHOW_MOBILE_CONTROLS_MAX]} {
    display: flex;
  }
`;

const IconWrapper = styled.div`
  position: relative;
`;

export const MobileControls = () => {
  const { showChat, updateShowChat } = useChat();
  const {
    localParticipant,
    changeMicStatus,
    changeCameraStatus,
    switchCamera,
    session,
    isChatUnread,
    isRecordingLoading,
    isRecording,
    availableDevices,
  } = useOpenViduProvider();
  const { isMeetingHost } = useMeeting();
  const { meetingModal, changeMeetingModal } = useRoom();

  const isPublisherReady = !!localParticipant?.streamManager;
  const enableCameraControls = isPublisherReady;
  const enableMicControls = isPublisherReady;
  const hasMultipleVideoDevices =
    (availableDevices || []).filter(device => device.kind === 'videoinput')
      .length > 1;

  const isMeetingInProgress = !!session;

  return (
    <ControlsContainer>
      {/* LEAVE CALL */}
      {isMeetingInProgress && (
        <Button
          icon={<IoMdCall size={18} />}
          onClick={() => changeMeetingModal(MEETING_MODAL.LEAVE)}
          variant='red'
        />
      )}
      {/* MICROPHONE */}
      <Button
        icon={
          localParticipant.audioActive ? (
            <IoMdMic size={19} />
          ) : (
            <IoMdMicOff size={19} />
          )
        }
        variant={localParticipant.audioActive ? 'secondary' : 'destructive'}
        onClick={() => changeMicStatus()}
        disabled={!enableMicControls}
      />

      {/* CAMERA */}
      <Button
        icon={
          localParticipant.videoActive ? (
            <BsCameraVideoFill size={16} />
          ) : (
            <BsCameraVideoOffFill size={16} />
          )
        }
        variant={localParticipant.videoActive ? 'secondary' : 'destructive'}
        onClick={() => changeCameraStatus()}
        disabled={!enableCameraControls}
      />
      {/* SWITCH CAMERA */}
      {hasMultipleVideoDevices && (
        <Button
          icon={<MdOutlineFlipCameraAndroid />}
          variant='secondary'
          onClick={() => switchCamera()}
        />
      )}
      {/* RECORDING */}
      {isMeetingInProgress && isMeetingHost && (
        <Button
          icon={
            isRecordingLoading ? (
              <Spinner size={12} />
            ) : isRecording ? (
              <MdStop size={22} />
            ) : (
              <BsRecord2 size={24} />
            )
          }
          color={isRecording ? 'destructive' : 'secondary'}
          onClick={() => changeMeetingModal(MEETING_MODAL.RECORDING)}
        />
      )}
      {/* CHAT */}
      {isMeetingInProgress && (
        <IconWrapper>
          {isChatUnread && <NofificationIndicator />}
          <Button
            icon={<IoMdChatboxes size={20} />}
            onClick={() => {
              updateShowChat(!showChat);
            }}
            variant={'secondary'}
          />
        </IconWrapper>
      )}
      {meetingModal === MEETING_MODAL.LEAVE && (
        <LeaveMeetingModal handleClose={() => changeMeetingModal(null)} />
      )}
      {meetingModal === MEETING_MODAL.RECORDING && (
        <RecordMeetingModal handleClose={() => changeMeetingModal(null)} />
      )}
    </ControlsContainer>
  );
};
