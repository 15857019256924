import styled from 'styled-components/macro';

export const SmallFormContainer = styled.div`
  box-shadow:
    0px 0px 4px rgba(66, 79, 104, 0.08),
    0px 12px 20px rgba(66, 79, 104, 0.06);
  border-radius: 6px;
  width: 424px;
  margin: 0 auto;
  padding: 32px;
  height: 'calc(100vh - (40px + 68px + 190px))';
  ${({ theme }) => theme.mediaQueryMaxWidth.sm} {
    width: 100%;
    box-shadow: none;
  }
`;
