import React from 'react';
import styled from 'styled-components/macro';
import { ParagraphBold, ParagraphSmall } from 'lib/style/typography';
import { IoIosWarning } from 'react-icons/io';
import { useCovideoTheme } from 'react-covideo-common';

interface Props {
  extendStyle?: {
    container: React.CSSProperties | undefined;
  };
}

const Container = styled.div`
  position: absolute;
  z-index: 10;
  top: 16px;
  right: 16px;
  width: 400px;
  max-width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.danger['non_alpha']};
  padding: 12px;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  box-shadow:
    0px 0px 4px rgba(99, 41, 35, 0.06),
    0px 12px 20px rgba(99, 41, 35, 0.04);
  border-radius: 6px;
  ${({ theme }) => theme.mediaQueryMaxWidth.sm} {
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
`;

const IconWrapper = styled.div`
  flex-shrink: 0;
`;

export const WaitingApprovalInfo = ({ extendStyle }: Props) => {
  const theme = useCovideoTheme();
  return (
    <Container style={extendStyle?.container}>
      <IconWrapper>
        <IoIosWarning size={24} color={theme.colors.neutral[60]} />
      </IconWrapper>
      <div>
        <ParagraphBold color={theme.colors.neutral[100]}>
          Waiting to enter the meeting...
        </ParagraphBold>
        <ParagraphSmall color={theme.colors.neutral[60]}>
          We have notified the host. You will be let in once host admits you to
          the call.
        </ParagraphSmall>
      </div>
    </Container>
  );
};
