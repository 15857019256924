import { Home } from 'app/pages/home/Home';
import { NotFound } from 'app/pages/not-found/NotFound';
import { Login } from 'app/pages/login/Login';
import { Logout } from 'app/pages/logout/Logout';
import { MeetingRoomsWrapper } from 'app/pages/meeting/MeetingRoomsWrapper';
import { ParticipantRemoved } from 'app/pages/participant-removed/ParticipantRemoved';

export const ROUTES = {
  home: {
    path: '/',
    component: Home,
  },
  login: {
    path: '/login',
    component: Login,
  },
  logout: {
    path: '/logout',
    component: Logout,
  },
  participantRemoved: {
    path: '/:id/removed',
    component: ParticipantRemoved,
  },
  meeting: {
    path: '/:id',
    component: MeetingRoomsWrapper,
  },
  notFound: {
    path: '*',
    component: NotFound,
  },
};
