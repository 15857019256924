import React from 'react';
import App from './app/App';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from 'lib/context/auth/AuthProvider';
import { SocketProvider } from 'lib/context/socket/SocketProvider';
import * as ReactDOM from 'react-dom';
import { CovideoThemeProvider } from 'react-covideo-common';
import GlobalStyles from 'lib/style/GlobalStyles';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { designSystem } from 'lib/utils/themes';
import { THEME_NAME } from 'types/utils';

Sentry.init({
  dsn: 'https://7ff5b44bf46de1b6812b334aff3c38a0@o109274.ingest.us.sentry.io/4507090162876416',
  integrations: [],
  environment: process.env.REACT_APP_ENV || 'development',
  enabled: ['sandbox', 'staging', 'production'].includes(
    process.env.REACT_APP_ENV as string
  ),
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: 0,
    },
  },
});

const themeName = (process.env.REACT_APP_WHITELABEL_NAME ||
  THEME_NAME.BRANDING) as THEME_NAME;

const appElement = (
  <React.StrictMode>
    <CovideoThemeProvider
      themeName={themeName}
      additionalStyles={{
        ...(designSystem as unknown as Record<string, string>),
        themeName,
      }}
    >
      <GlobalStyles />
      <ToastContainer style={{ minWidth: 300, width: 'auto', maxWidth: 400 }} />
      <SocketProvider>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <App />
          </AuthProvider>
        </QueryClientProvider>
      </SocketProvider>
    </CovideoThemeProvider>
  </React.StrictMode>
);

ReactDOM.render(appElement, document.getElementById('root'));
