import { Form, Formik } from 'formik';
import { Tabs } from 'lib/components/tabs';
import React, { useState } from 'react';
import * as Yup from 'yup';

import {
  FORM_SETTINGS_ITEMS,
  SETTINGS_VALUES,
} from 'lib/components/tabs/const';
import { Flex } from 'lib/style/layout';
import styled from 'styled-components/macro';
import { MicIndicator } from './MicIndicator';
import { FieldSelect } from 'lib/components/formik/dropdown';
import { Option } from 'lib/forms/types';
import { useOpenViduProvider } from 'lib/context/openvidu/OpenViduProvider';
import { VIDEO_RESOLUTION_OPTIONS } from 'lib/context/openvidu/const';
import { Button } from 'react-covideo-common';

interface StreamSettingsFormValues {
  videoSource: Option;
  resolution: Option;
  audioSource: Option;
}

const StreamSettingsSchema = Yup.object().shape({
  resolution: Yup.object().shape({
    value: Yup.string().required(),
    label: Yup.string().required(),
  }),
  audioSource: Yup.object().shape({
    value: Yup.string().required(),
    label: Yup.string().required(),
  }),
  videoSource: Yup.object().shape({
    value: Yup.string().required(),
    label: Yup.string().required(),
  }),
});

type Props = {
  onModalClose: () => void;
};

const FormBody = styled.div`
  min-height: 280px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: ${({ theme }) => theme.colors.neutral[100]};
`;

export const StreamSettings = ({ onModalClose }: Props) => {
  const {
    changeDeviceSettings,
    resolution,
    currentAudioDevice,
    currentVideoDevice,
    availableDevices,
  } = useOpenViduProvider();

  const [activeTab, setActiveTab] = useState<SETTINGS_VALUES>(
    SETTINGS_VALUES.AUDIO
  );
  const [micDeviceId, setMicDeviceId] = useState<string>();

  const handleSubmit = async (values: StreamSettingsFormValues) => {
    changeDeviceSettings({
      videoDeviceId: values.videoSource.value,
      audioDeviceId: values.audioSource.value,
      resolution: values.resolution.value,
    });
    onModalClose();
  };

  const videoSourceOptions = (availableDevices || [])
    .filter(device => device.kind === 'videoinput')
    .map(device => ({
      value: device.deviceId,
      label: device.label,
    }));

  const audioSourceOptions = (availableDevices || [])
    .filter(device => device.kind === 'audioinput')
    .map(device => ({ value: device.deviceId, label: device.label }));

  const initialValues = {
    resolution: resolution
      ? VIDEO_RESOLUTION_OPTIONS.find(option => option.value === resolution) ||
        VIDEO_RESOLUTION_OPTIONS[1]
      : VIDEO_RESOLUTION_OPTIONS[1],
    audioSource: currentAudioDevice?.deviceId
      ? {
          label: currentAudioDevice?.label,
          value: currentAudioDevice?.deviceId,
        }
      : audioSourceOptions[0],
    videoSource: currentVideoDevice?.deviceId
      ? {
          label: currentVideoDevice?.label,
          value: currentVideoDevice?.deviceId,
        }
      : videoSourceOptions[0],
  };

  return (
    <>
      <Tabs
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        items={FORM_SETTINGS_ITEMS}
      />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={StreamSettingsSchema}
        onSubmit={handleSubmit}
      >
        {({ errors }) => (
          <Form>
            <FormBody>
              {activeTab === SETTINGS_VALUES.VIDEO && (
                <>
                  <FieldSelect
                    label={'Video Source'}
                    name={'videoSource'}
                    placeholder={'Video Source'}
                    options={videoSourceOptions}
                    error={errors?.videoSource?.value || ''}
                  />
                  <FieldSelect
                    label={'Video Quality'}
                    name={'resolution'}
                    placeholder={'Video Quality'}
                    options={VIDEO_RESOLUTION_OPTIONS}
                    error={errors?.resolution?.value || ''}
                  />
                </>
              )}
              {activeTab === SETTINGS_VALUES.AUDIO && (
                <>
                  <FieldSelect
                    label={'Microphone Source'}
                    name={'audioSource'}
                    placeholder={'Microphone Source'}
                    options={audioSourceOptions}
                    onChange={option => {
                      setMicDeviceId(option?.value);
                    }}
                    error={errors?.audioSource?.value || ''}
                  />
                  <MicIndicator micDeviceId={micDeviceId} />
                </>
              )}
            </FormBody>
            <Flex justifyContent='flex-end' gap='12px'>
              <Button
                variant='secondary'
                type='button'
                text='Cancel'
                onClick={onModalClose}
              />
              <Button type='submit' text='Save Changes' />
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
};
