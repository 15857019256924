import { MdClose } from 'react-icons/md';
import React from 'react';
import { ToastProps } from './types';

export const CloseToastIcon = (props?: ToastProps) => {
  const alignItems = props?.additionalInfo ? 'flex-start' : 'center';
  const padding = props?.additionalInfo ? '4px' : '0';
  return (
    <div style={{ display: 'flex', alignItems: alignItems, padding: padding }}>
      <MdClose size={24} color='rgba(146, 151, 162, 1)' />
    </div>
  );
};
