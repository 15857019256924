import { AxiosError } from 'axios';
import { useToastError } from 'lib/hooks';
import { MutationFunction, useMutation } from 'react-query';
import api, { token } from '..';
import {
  ICreateSessionConnectionRequest,
  ICreateSessionConnectionResponse,
} from './types';

const createSessionConnection: MutationFunction<
  ICreateSessionConnectionResponse,
  ICreateSessionConnectionRequest
> = async ({ sessionId, isScreen }) => {
  const isAuthorized = !!token;
  const response = await api.post(
    `/openvidu/session/${sessionId}/${
      isAuthorized ? 'authorized' : 'guest'
    }-connection`,
    { isScreen }
  );
  if (!response.data) {
    throw new Error('No data found');
  }
  return response.data;
};

export const useCreateSessionConnectionMutation = () => {
  const { showError } = useToastError();

  return useMutation<
    ICreateSessionConnectionResponse,
    AxiosError,
    ICreateSessionConnectionRequest
  >(createSessionConnection, {
    onError: (err: AxiosError) => {
      showError(err, 'Something went wrong when connecting to meeting.');
    },
  });
};
