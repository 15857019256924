import React, { createContext, memo, useContext } from 'react';
import { useGetSingleMeeting } from 'lib/api/meeting/getSingleMeeting';
import { IMeeting } from 'lib/api/meeting/types';
import { useInvalidateMeetingQuery } from 'lib/api/meeting/invalidateMeetingQuery';
import { useAuth } from '../auth/AuthProvider';
import { useNotifyHost } from 'lib/api/meeting/notifyHost';
import { useSockets } from '../socket/SocketProvider';
import { ISOCKET_ACTIONS } from '../socket/types';
import { useUpdateMeetingToStartedMutation } from 'lib/api/meeting/updateMeetingToStarted';
import { useUpdateMeetingToFinishedMutation } from 'lib/api/meeting/updateMeetingToFinished';

interface IMeetingContext {
  meeting: IMeeting | null;
  isMeetingLoading: boolean;
  isMeetingFetching: boolean;
  isError: boolean;
  isMeetingHost: boolean;
  updateMeetingToStarted: () => Promise<void>;
  updateMeetingToFinished: () => Promise<void>;
  notifyHostParticipantArrived: () => Promise<void>;
  invalidateMeeting: () => void;
  refetchMeeting: () => Promise<void>;
}

const MeetingContext = createContext({} as IMeetingContext);

export const MeetingProvider = memo(
  ({
    meetingId,
    children,
  }: {
    meetingId: string;
    children: React.ReactNode;
  }) => {
    const { userData } = useAuth();
    const {
      isLoading: isMeetingLoading,
      isFetching: isMeetingFetching,
      isError,
      data: meeting = null,
      refetch,
    } = useGetSingleMeeting(meetingId);
    const updateMeetingToStartedMutation = useUpdateMeetingToStartedMutation();
    const updateMeetingToFinishedMutation =
      useUpdateMeetingToFinishedMutation();
    const invalidateMeeting = useInvalidateMeetingQuery();
    const notifyHost = useNotifyHost();
    const { socket } = useSockets();

    const updateMeetingToStarted = async () => {
      await updateMeetingToStartedMutation.mutateAsync(meetingId);
      if (socket) {
        socket.emit(ISOCKET_ACTIONS.HOST_STARTED_MEETING, { meetingId });
      }
    };

    const updateMeetingToFinished = async () => {
      await updateMeetingToFinishedMutation.mutateAsync(meetingId);
      if (socket) {
        socket.emit(ISOCKET_ACTIONS.HOST_ENDED_MEETING, { meetingId });
      }
    };

    const notifyHostParticipantArrived = async () => {
      if (userData?.id === meeting?.host?.id) {
        return;
      }
      await notifyHost.mutateAsync(meetingId);
    };

    const refetchMeeting = async () => {
      await refetch();
    };

    const invalidateCurrentMeeting = async () => {
      invalidateMeeting(meetingId);
    };

    const isMeetingHost = userData?.id === meeting?.host?.id;

    const values: IMeetingContext = {
      isMeetingLoading,
      isMeetingFetching,
      isError,
      meeting,
      updateMeetingToStarted,
      updateMeetingToFinished,
      invalidateMeeting: invalidateCurrentMeeting,
      isMeetingHost,
      notifyHostParticipantArrived,
      refetchMeeting,
    };

    return (
      <MeetingContext.Provider value={values}>
        {children}
      </MeetingContext.Provider>
    );
  }
);

export const useMeeting = () => {
  const context = useContext(MeetingContext);
  if (context === undefined) {
    throw new Error('useMeeting must be used within a MeetingProvider');
  }
  return context;
};
