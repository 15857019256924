import { HiMiniSpeakerWave } from 'react-icons/hi2';
import { MdVideocam } from 'react-icons/md';
import React from 'react';

export enum SETTINGS_VALUES {
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO',
  PERMISSIONS = 'PERMISSIONS',
}
export const FORM_SETTINGS_ITEMS = [
  {
    id: SETTINGS_VALUES.AUDIO,
    label: 'Audio',
    icon: <HiMiniSpeakerWave size={20} />,
    value: SETTINGS_VALUES.AUDIO,
  },
  {
    id: SETTINGS_VALUES.VIDEO,
    label: 'Video',
    icon: <MdVideocam size={20} />,
    value: SETTINGS_VALUES.VIDEO,
  },
];
