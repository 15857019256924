import { useField } from 'formik';
import React, { RefObject } from 'react';
import { TextInput } from '../form-parts/TextInput';

interface Props {
  name: string;
  label?: string;
  autoComplete?: 'on' | 'off';
  autoFocus?: boolean;
  placeholder: string;
  type: 'text' | 'email' | 'password';
  innerRef?: RefObject<HTMLInputElement>;
  maxLength?: number;
}

export const TextField = (props: Props) => {
  const [field, meta] = useField(props.name);
  const isError = meta.touched && meta.error;
  return <TextInput {...props} {...field} isError={!!isError} />;
};
