import React, { memo, useEffect, useRef } from 'react';
import { IoMdMic, IoMdMicOff } from 'react-icons/io';
import { Participant } from 'lib/context/openvidu/type';
import { RemoveParticipantButton } from '../controls/RemoveParticipantButton';
import { useOpenViduProvider } from 'lib/context/openvidu/OpenViduProvider';
import {
  CameraOffWrapper,
  MicStatus,
  MicStatusWrapper,
  PeerInfoWrapper,
  PeerName,
  ScreenShareInfo,
  ScreenShareInfoWrapper,
  Video,
} from '../../style';
import { MdPerson } from 'react-icons/md';
import { useCovideoTheme } from 'react-covideo-common';

type Props = {
  participant: Participant;
};

export const ParticipantVideo = memo(({ participant }: Props) => {
  const { speakers } = useOpenViduProvider();
  const theme = useCovideoTheme();
  const ref = useRef<HTMLVideoElement | null>(null);
  useEffect(() => {
    if (!ref.current || !participant.streamManager) {
      return;
    }
    participant.streamManager.addVideoElement(ref.current);
  }, [participant.streamManager]);

  if (!participant.streamManager) {
    return null;
  }

  const isInCall = !!participant.connectionId;
  const isVideoActive = !!participant.videoActive;
  const isAudioActive = !!participant.audioActive;
  const isSpeaking =
    isInCall && isAudioActive && speakers.has(participant.connectionId);

  return (
    <>
      {isInCall && !participant?.isScreen && (
        <PeerInfoWrapper>
          <PeerName hasException={!!participant?.hasException}>
            {participant.type === 'local' ? 'You' : participant.nickname}
          </PeerName>
          <MicStatusWrapper status={isAudioActive}>
            <MicStatus isSpeaking={isSpeaking} />
            {isAudioActive && (
              <IoMdMic
                size={18}
                color={theme.colors.white[100]}
                style={{ position: 'relative' }}
              />
            )}
            {!isAudioActive && (
              <IoMdMicOff size={18} color={theme.colors.white[100]} />
            )}
          </MicStatusWrapper>
        </PeerInfoWrapper>
      )}
      {isInCall && participant?.isScreen && (
        <ScreenShareInfoWrapper>
          <ScreenShareInfo>
            {`${participant.nickname} is sharing screen.`}
          </ScreenShareInfo>
        </ScreenShareInfoWrapper>
      )}
      <Video
        autoPlay={true}
        ref={ref}
        isScreen={!!participant?.isScreen}
        isSpeaking={isSpeaking}
      />
      {!isVideoActive && (
        <CameraOffWrapper>
          <MdPerson size={'60%'} color={'white'} opacity={0.6} />
        </CameraOffWrapper>
      )}
      <RemoveParticipantButton participant={participant} />
    </>
  );
});
