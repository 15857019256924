import React, { useMemo, useState } from 'react';
import { io } from 'socket.io-client';
import { apiExpress, isEnvDevelopment } from '../../config';
import { ISocket, ISOCKET_ACTIONS } from './types';

const config = {
  path: '/socket.io',
  secure: true,
  rejectUnauthorized: false,
  reconnectionAttempts: 5,
  reconnectionDelay: 2000,
  reconnectionDelayMax: 10000,
  transports: ['websocket'],
};

interface ISocketContext {
  socket: ISocket | null;
}

const SocketContext = React.createContext({} as ISocketContext);

const URL_PATH = isEnvDevelopment ? 'https://localhost:3000' : apiExpress;

export const SocketProvider = ({ children }: { children: React.ReactNode }) => {
  const [socket, setSocket] = useState<ISocket | null>(null);

  React.useEffect(() => {
    if (socket === null) {
      setSocket(io(URL_PATH, config));
    }
  }, []);

  React.useEffect(() => {
    if (socket === null) {
      return;
    }
    const cleanup = () => {
      socket?.disconnect();
    };
    window.addEventListener('beforeunload', cleanup);
    socket.on(ISOCKET_ACTIONS.CONNECT, () => {
      console.log('socket connected', socket.id);
    });

    socket.on(ISOCKET_ACTIONS.DISCONNECT, () => {
      console.log('socket disconnected');
    });

    return () => {
      window.removeEventListener('beforeunload', cleanup);
    };
  }, [socket]);

  const value = useMemo(() => ({ socket }), [socket]);

  return (
    <SocketContext.Provider value={value}>{children}</SocketContext.Provider>
  );
};

export const useSockets = () => {
  const context = React.useContext(SocketContext);
  if (context === undefined) {
    throw new Error('useSockets must be used within a SocketProvider');
  }
  return context;
};
