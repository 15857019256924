import React, { useEffect } from 'react';
import { MainNavigation } from 'lib/components/navigation/MainNavigation';
import { ReactComponent as LiveFinished } from 'app/assets/images/liveEnded.svg';
import { Heading } from 'lib/style/typography';

import { useMeeting } from 'lib/context/meeting/MeetingProvider';
import { Flex, MainContainer } from 'lib/style/layout';
import { useSockets } from 'lib/context/socket/SocketProvider';
import { infoSuccessToast } from 'lib/components/toasts/infoSuccess';
import { ISOCKET_ACTIONS } from 'lib/context/socket/types';
import { useOpenViduProvider } from 'lib/context/openvidu/OpenViduProvider';
import { Button, useCovideoTheme } from 'react-covideo-common';
import styled from 'styled-components/macro';

const Image = styled(LiveFinished)`
  max-width: 100%;
  display: block;
  margin: 0 auto;
`;

export const Finished = () => {
  const { meeting, updateMeetingToStarted, isMeetingHost, invalidateMeeting } =
    useMeeting();
  const theme = useCovideoTheme();
  const { socket } = useSockets();
  const { leaveSession } = useOpenViduProvider();

  useEffect(() => {
    leaveSession();
  }, []);

  useEffect(() => {
    if (!meeting || !socket || isMeetingHost) {
      return;
    }
    const handleMeetingStarted = () => {
      infoSuccessToast({ title: `Host started the meeting. ` });
      invalidateMeeting();
    };
    socket.on(ISOCKET_ACTIONS.HOST_STARTED_MEETING, handleMeetingStarted);
    const cleanup = () => {
      socket.removeAllListeners(ISOCKET_ACTIONS.HOST_STARTED_MEETING);
    };
    return cleanup;
  }, [meeting, socket]);

  const handleRestartMeeting = async () => {
    if (!meeting) {
      return;
    }
    await updateMeetingToStarted();
  };

  return (
    <MainContainer>
      <MainNavigation
        extendStyle={{ container: { marginBottom: 24 } }}
        imageUrl={meeting?.landingImageUrl}
      />
      <Heading
        color={theme.colors.black[100]}
        m='0 0 24px 0'
        textAlign='center'
      >
        Meeting Ended
      </Heading>
      {isMeetingHost && (
        <Flex justifyContent='center' m='0 0 24px 0'>
          <Button text='Restart Meeting?' onClick={handleRestartMeeting} />
        </Flex>
      )}
      <Image />
    </MainContainer>
  );
};
