import { LoadingIndicator } from 'lib/components/loader/Loader';
import { useOpenViduProvider } from 'lib/context/openvidu/OpenViduProvider';
import React, { memo, useEffect, useCallback } from 'react';
import { Controls } from '../controls/Controls';
import { MobileControls } from '../controls/MobileControls';
import { RecordingIndicator } from '../controls/RecordingIndicator';
import { useMeeting } from 'lib/context/meeting/MeetingProvider';
import { WaitingApproval } from '../controls/WaitingApproval';
import { Participant } from 'lib/context/openvidu/type';
import { calculateLayout, getParticipantPriority } from 'lib/utils/utils';
import { FadeLoader } from 'react-spinners';
import useWindowSize from 'react-use/lib/useWindowSize';
import {
  GALLERY_PADDING_SIDE,
  CONTROLS_HEIGHT,
  PARTICIPANT_VIDEO_ASPECT_RATIO,
  SIDEBAR_VIDEO_HEIGHT,
  SIDEBAR_VIDEO_WIDTH,
} from '../../const';
import { ParticipantVideo } from '../participant/ParticipantVideo';
import {
  ParticipantsContainer,
  ParticipantItem,
  SessionWrapper,
  ParticipantBoxWrapper,
  ScreenShareLoading,
  ScreenWrapper,
} from '../../style';
import { useCovideoTheme } from 'react-covideo-common';

export const Session = memo(() => {
  const theme = useCovideoTheme();
  const containerRef = React.createRef<HTMLDivElement>();
  const { isMeetingHost } = useMeeting();
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const {
    remoteParticipants,
    localParticipant,
    screenShareParticipant,
    isScreenShareLoading,
    leaveSession,
  } = useOpenViduProvider();

  const getParticipants = useCallback(
    (localParticipant: Participant, remoteParticipants: Participant[]) => {
      const participants: Participant[] = [
        localParticipant,
        ...(remoteParticipants || []),
      ];
      return {
        participants,
      };
    },
    [localParticipant, remoteParticipants]
  );

  const { participants } = getParticipants(
    localParticipant,
    remoteParticipants
  );

  const hasSidebar = !!screenShareParticipant || isScreenShareLoading;

  const layoutContainerSize = {
    height: windowHeight - CONTROLS_HEIGHT,
    width: windowWidth - GALLERY_PADDING_SIDE,
  };

  const { width, height, cols } = hasSidebar
    ? {
        width: SIDEBAR_VIDEO_WIDTH,
        height: SIDEBAR_VIDEO_HEIGHT,
        cols: 1,
      }
    : calculateLayout(
        layoutContainerSize.width,
        layoutContainerSize.height,
        participants.length,
        PARTICIPANT_VIDEO_ASPECT_RATIO
      );

  useEffect(() => {
    const beforeUnloadCallback = () => {
      // leave session on unload
      leaveSession();
    };
    window.addEventListener('beforeunload', beforeUnloadCallback);
    return () => {
      // leave session on unmount
      leaveSession();
      window.removeEventListener('beforeunload', beforeUnloadCallback);
    };
  }, []);

  if (!localParticipant?.connectionId) {
    return <LoadingIndicator />;
  }

  return (
    <SessionWrapper ref={containerRef} hasSidebar={hasSidebar}>
      {isScreenShareLoading && (
        <ScreenShareLoading>
          <FadeLoader
            height={15}
            width={5}
            color={theme.colors.white[100]}
            loading={true}
          />
        </ScreenShareLoading>
      )}
      {(!!screenShareParticipant || isScreenShareLoading) && (
        <ScreenWrapper>
          <ParticipantBoxWrapper isLoading={isScreenShareLoading}>
            {screenShareParticipant && (
              <ParticipantVideo participant={screenShareParticipant} />
            )}
          </ParticipantBoxWrapper>
        </ScreenWrapper>
      )}
      <ParticipantsContainer
        itemWidth={`${width}px`}
        cols={cols.toString()}
        isSidebar={hasSidebar}
      >
        {(participants || []).map((participant, i) => (
          <ParticipantItem
            key={i}
            itemWidth={`${width}px`}
            itemHeight={`${height}px`}
            order={getParticipantPriority(participant)}
          >
            <ParticipantBoxWrapper>
              <ParticipantVideo
                participant={participant}
                key={participant.connectionId}
              />
            </ParticipantBoxWrapper>
          </ParticipantItem>
        ))}
      </ParticipantsContainer>
      <RecordingIndicator />
      <Controls />
      <MobileControls />
      {isMeetingHost && <WaitingApproval />}
    </SessionWrapper>
  );
});
