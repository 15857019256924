import { useToastError } from 'lib/hooks';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  position: relative;
  height: 4px;
  margin: 8px 0;
`;

interface IIndicator {
  scale: number;
}

const Indicator = styled.div.attrs<{ scale: number }>(({ scale }) => ({
  style: {
    transform: `scaleX(${scale})`,
  },
}))<IIndicator>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #1ea35f 0%, #ffb800 50%, #e84c3d 100%);
  transform-origin: left center;
`;

interface Props {
  micDeviceId?: string;
}

export const MicIndicator = ({ micDeviceId }: Props) => {
  const { showError } = useToastError();
  const [micLevel, setMicLevel] = useState<number>(0);
  const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
  const [analyser, setAnalyser] = useState<AnalyserNode | null>(null);

  useEffect(() => {
    handleMicChange(micDeviceId || '');
  }, [micDeviceId]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (analyser) {
        const dataArray = new Uint8Array(analyser.fftSize);
        analyser.getByteFrequencyData(dataArray);
        const values = dataArray.reduce((a, b) => a + b) / dataArray.length;
        setMicLevel(values);
      }
    }, 100);

    return () => {
      clearInterval(intervalId);
      if (audioContext && audioContext.state !== 'closed') {
        audioContext.close();
      }
    };
  }, [analyser]);

  const handleMicChange = async (deviceId: string) => {
    try {
      if (audioContext && analyser) {
        // Clean up the old stream
        audioContext.close();
      }

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: deviceId ? { deviceId: deviceId } : true,
      });

      const context = new AudioContext();
      const source = context.createMediaStreamSource(stream);
      const newAnalyser = context.createAnalyser();
      newAnalyser.fftSize = 512;
      source.connect(newAnalyser);

      setAudioContext(context);
      setAnalyser(newAnalyser);
    } catch (error) {
      showError(error, 'Something went wrong when toggling microphone.');
    }
  };

  return (
    <Wrapper>
      <Indicator scale={Math.min(micLevel / 30, 1)} />
    </Wrapper>
  );
};
