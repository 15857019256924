import { useQuery } from 'react-query';
import { meetingKeys } from './meetingKeys';
import { MeetingRequest } from './types';
import api from '..';

const getActiveMeetingRequest = async (
  meetingId: string,
  browserHash: string
): Promise<{ meetingRequest: MeetingRequest } | undefined> => {
  try {
    const response = await api.get(`/live/${meetingId}/request/${browserHash}`);
    const { data: approvalRequests } = response;
    return approvalRequests as { meetingRequest: MeetingRequest };
  } catch (error) {
    return undefined;
  }
};

export const useGetActiveMeetingRequest = (
  meetingId: string,
  browserHash: string,
  isMeetingHost: boolean
) => {
  return useQuery(
    meetingKeys.meetingActiveRequest(),
    () => getActiveMeetingRequest(meetingId, browserHash),
    {
      enabled: !!meetingId && !!browserHash && !isMeetingHost,
    }
  );
};
