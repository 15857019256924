import { useMutation } from 'react-query';
import api from '..';
import { useToastError } from 'lib/hooks';
import { AxiosError } from 'axios';

const notifyHost = async (meetingId: string) => {
  const response = (await api.post(`/live/${meetingId}/notify-host`)).data;
  if (!response) {
    throw new Error('An error occurred');
  }
  return response;
};

export const useNotifyHost = () => {
  const { showError } = useToastError();
  return useMutation(notifyHost, {
    onError: (error: AxiosError) => {
      showError(error, 'Something went wrong when notifying host.');
    },
  });
};
