import { keyframes } from 'styled-components/macro';

export const pulse = keyframes`
	0% {
		scale: 0.95;
		box-shadow: 0 0 0 0 rgba(0,255,0, 0.7);
	}
	
	90% {
		scale: 1;
		box-shadow: 0 0 0 8px rgba(255,255,255, 0);
	}
	
	100% {
		scale: 0.95;
		box-shadow: 0 0 0 0 rgba(255,255,255, 0);
	}
`;
