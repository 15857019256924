import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Subscriber } from 'openvidu-browser';
import { IMeeting, MeetingTime, MEETING_STATUS } from 'lib/api/meeting/types';
import { Participant } from 'lib/context/openvidu/type';
import { ITimezone, timezones } from 'lib/constants/timezone';

export const isAndroid = /(android)/i.test(navigator.userAgent);
export const isIOS = /iPad|iPhone|iPod/.test(navigator.platform);

export const formatMeetingTime = (
  dateString: string,
  duration: number
): MeetingTime => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const dateParts: string[] = dateString.split(';');
  const hasTimezone: boolean = dateParts.length === 4;
  const meetingTimezone: string = hasTimezone ? dateParts[3] : '';

  const date: dayjs.Dayjs = hasTimezone
    ? dayjs.tz(
        `${dateParts[0]} ${dateParts[1]} ${dateParts[2]}`,
        'YYYY-MM-DD hh:mm A',
        meetingTimezone
      )
    : dayjs(
        `${dateParts[0]} ${dateParts[1]} ${dateParts[2]}`,
        'YYYY-MM-DD hh:mm A'
      );

  const day: string = date.format('DD/MM/YYYY');
  const startTime: string = date.format('hh:mm A');
  const endTime: string = date
    .clone()
    .add(duration, 'minutes')
    .format('hh:mm A');

  const meetingDuration = hasTimezone
    ? `${startTime} - ${endTime}, ${meetingTimezone}`
    : `${startTime} - ${endTime}`;

  return {
    meetingDuration,
    day,
  };
};

export const calculateDiff = (pastDate: string) => {
  const last = dayjs(pastDate);
  const today = dayjs();

  const MINUTES = 60;
  const MINUTES_PER_DAY = 60 * 24;
  const MINUTES_MONTHS = 60 * 24 * 30;
  const MINUTES_PER_YEAR = 60 * 24 * 365;
  const diff = today.diff(pastDate, 'minute');
  // added so no negative diff is possible
  if (diff < 1) {
    return `Now`;
  }
  if (diff < MINUTES) {
    return `${diff === 0 ? 1 : diff} minute${diff <= 1 ? '' : 's'} ago`;
  }
  if (diff > MINUTES && diff < MINUTES_PER_DAY) {
    const hoursDiff = today.diff(last, 'hour');
    return `${hoursDiff + 1} hour${hoursDiff === 1 ? '' : 's'} ago`;
  }
  if (diff > MINUTES_PER_DAY && diff < MINUTES_MONTHS) {
    const daysDiff = today.diff(last, 'day');
    return `${daysDiff + 1} day${daysDiff <= 0 ? '' : 's'} ago`;
  }
  if (diff > MINUTES_PER_DAY && diff < MINUTES_PER_YEAR) {
    const monthsDiff = today.diff(last, 'month');
    return `${monthsDiff + 1} month${monthsDiff === 1 ? '' : 's'} ago`;
  }
  if (diff > MINUTES_PER_YEAR) {
    const yearsDiff = today.diff(last, 'year');
    return `${yearsDiff + 1} year${yearsDiff === 1 ? '' : 's'} ago`;
  }
};

export const addLinksToText = (text: string): string => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(
    urlRegex,
    '<a href="$&" target="_blank" rel="noopener noreferrer">$&</a>'
  );
};

export function meetingShouldStartSoon(meeting: IMeeting): boolean {
  if (meeting.meetingStatus === MEETING_STATUS.STARTED) {
    return true;
  }
  const meetingTime = meeting.nextOccurrence;
  // if time not set meeting should start soon
  if (!meetingTime) {
    return true;
  }
  const currentTime = Math.floor(Date.now() / 1000);
  const differenceInSeconds = meetingTime - currentTime;
  // If difference is less than or equal to 600 seconds (10 minutes), return true
  return differenceInSeconds <= 600;
}

export const getDataFromSubscriber = (subscriber: Subscriber) => {
  const json = subscriber?.stream?.connection?.data;
  return json ? JSON.parse(json) || {} : {};
};

export const getInitials = (name: string) => {
  return name
    .split(' ')
    .map(word => word[0]?.toUpperCase() || '')
    .join('');
};

export const getRandomNickname = () => {
  return `Guest${Math.floor(Math.random() * 1000)}`;
};

export function getUnknownProperty(obj: unknown, key: string): unknown {
  if (typeof obj === 'object' && obj !== null && key in obj) {
    return (obj as Record<string, unknown>)[key];
  }
  return undefined;
}

export const getParticipantPriority = (participant: Participant) => {
  const { type, videoActive, audioActive } = participant;
  if (type === 'local') {
    return 1;
  }
  if (videoActive && audioActive) {
    return 2;
  }
  if (audioActive) {
    return 3;
  }
  if (videoActive) {
    return 4;
  }
  return 5;
};

const getTimezoneByUtc = (utcValue: string): ITimezone | null => {
  return timezones.find(tz => tz.utc.includes(utcValue)) || null;
};

export const generateScheduledTime = (
  meeting: IMeeting | null
): { scheduledDateTime: string; scheduledTimezone: string } => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  if (!meeting || !meeting.deliveryTime || !meeting.nextOccurrence) {
    return { scheduledDateTime: '', scheduledTimezone: '' };
  }

  const { deliveryTime, nextOccurrence } = meeting;
  const [_date, _time, _ampm, timezoneUtc] = deliveryTime.split(';') || [];
  const tzOption = getTimezoneByUtc(timezoneUtc);
  if (!tzOption) {
    return { scheduledDateTime: '', scheduledTimezone: '' };
  }

  const scheduledTimezone = tzOption.text;
  const date = dayjs.unix(nextOccurrence).tz(timezoneUtc);
  const scheduledDateTime = date.format('ddd, MMM D, YYYY, h:mm A');

  return { scheduledDateTime, scheduledTimezone };
};

export const calculateLayout = (
  containerWidth: number,
  containerHeight: number,
  videoCount: number,
  aspectRatio: number
): { width: number; height: number; cols: number } => {
  let bestLayout = {
    area: 0,
    cols: 0,
    rows: 0,
    width: 0,
    height: 0,
  };

  // brute-force search layout where video occupy the largest area of the container
  for (let cols = 1; cols <= videoCount; cols++) {
    const rows = Math.ceil(videoCount / cols);
    const hScale = containerWidth / (cols * aspectRatio);
    const vScale = containerHeight / rows;
    let width;
    let height;
    if (hScale <= vScale) {
      width = Math.floor(containerWidth / cols);
      height = Math.floor(width / aspectRatio);
    } else {
      height = Math.floor(containerHeight / rows);
      width = Math.floor(height * aspectRatio);
    }
    const area = width * height;
    if (area > bestLayout.area) {
      bestLayout = {
        area,
        width,
        height,
        rows,
        cols,
      };
    }
  }
  return bestLayout;
};
