import React from 'react';
import { JoinMeetingForm } from 'lib/forms/JoinMeeting';
import { Heading } from 'lib/style/typography';
import { MainContainer } from 'lib/style/layout';
import { SmallFormContainer } from 'lib/forms/style';
import { MainNavigation } from 'lib/components/navigation/MainNavigation';
import { MeetingInfo } from 'lib/components/info/MeetingInfo';
import { useCovideoTheme } from 'react-covideo-common';

export const Home = () => {
  const theme = useCovideoTheme();

  console.log('process.env', process.env);
  console.log('theme.themeName', theme.themeName);

  return (
    <MainContainer>
      <MainNavigation />

      <SmallFormContainer>
        <Heading
          color={theme.colors.black[100]}
          m='0 0 24px 0'
          textAlign='center'
        >
          Join meeting
        </Heading>
        <JoinMeetingForm />
      </SmallFormContainer>
      <MeetingInfo />
    </MainContainer>
  );
};
