import axios from 'axios';
import Cookies from 'js-cookie';
import { COOKIE_JWT, apiExpress } from 'lib/config';

export const token = Cookies.get(COOKIE_JWT);
const headers = !token ? undefined : { Authorization: `Bearer ${token}` };

const api = axios.create({
  baseURL: apiExpress,
  headers,
});

export default api;
