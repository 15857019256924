import { useQuery } from 'react-query';
import { userKeys } from './userKeys';
import api from '..';
import { IUser } from './types';

const getUser = async (): Promise<IUser> => {
  const response = await api.get(`/auth/user`);
  const { data: user } = response;
  return user as IUser;
};

export const useGetUserQuery = ({ enabled }: { enabled: boolean }) => {
  return useQuery(userKeys.user(), getUser, { enabled });
};
