import styled from 'styled-components/macro';

export const MainContainer = styled.div`
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  min-height: 100%;
  padding: 0 16px;
  position: relative;
  .stream-settings {
    display: none;
  }
`;

interface IFlex {
  gap?: string;
  m?: string;
  flexWrap?: string;
  flexDirection?: string;
  alignItems?: string;
  justifyContent?: string;
  flex?: string;
}

export const Flex = styled.div<IFlex>`
  display: flex;
  flex-wrap: ${({ flexWrap }) => (flexWrap ? flexWrap : 'wrap')};
  margin: ${({ m }) => m || '0px'};
  gap: ${({ gap }) => gap || '16px'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  width: 100%;
  flex: ${({ flex }) => flex || 'unset'};
`;
