import Pill from 'lib/components/pill';
import { useOpenViduProvider } from 'lib/context/openvidu/OpenViduProvider';
import React from 'react';
import { useCovideoTheme } from 'react-covideo-common';
import { BsRecordFill } from 'react-icons/bs';
import styled from 'styled-components/macro';

const PillWrapper = styled.div`
  position: absolute;
  z-index: 4;
  bottom: 100px;
  left: 20px;
`;

export const RecordingIndicator = () => {
  const theme = useCovideoTheme();
  const { isRecording } = useOpenViduProvider();
  if (!isRecording) {
    return null;
  }
  return (
    <PillWrapper>
      <Pill
        text='Recording'
        icon={<BsRecordFill color={theme.colors.danger[100]} />}
      />
    </PillWrapper>
  );
};
